import { createSlice } from '@reduxjs/toolkit';
import searchApi, { getFilterString } from './search-service';
import { postMessage } from 'store/slices/messages/messages'; 
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  results: [] 
}

const sliceName = 'searchSuggestions';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSuggestionsStarting: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setSuggestionsSuccess: (state, { payload }) => {
      const { results } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.results = results;
    },
    setSuggestionsFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setSuggestionsStarting, setSuggestionsSuccess, setSuggestionsFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer

export const getSuggestions = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setSuggestionsStarting());
      const tokenPromise = GetToken();
      const token = await tokenPromise;

      const currentState = getState();  
      const search = currentState.searchText.searchText || "*";
      //const searchFields = currentState.searchFields.searchFields;

      const facetedFilters = { ...currentState.searchFilters };
      delete facetedFilters["ActiveYears"];
      const filterString = getFilterString(facetedFilters, currentState.searchActiveOnly.searchActiveOnly, currentState.searchFilters["ActiveYears"]);

      const results = await searchApi.getSuggestions(
        search,
        'projects-index',
        "projects-suggester",
        "ProjectName,ProjectClientName",
        "ProjectId,ProjectName,ProjectClientName,ProjectCode,ContractCode,ProjectMarketSegmentName",
        10,
        token.idToken,
        filterString,
      );
      
      const parsedResults = parseResults(results);
      dispatch(setSuggestionsSuccess({ results: parsedResults }));
    } catch (error) {
      dispatch(setSuggestionsFailure());
      postMessage({ type: "warning", text: "Suggestions Error: " + error });
    }
  }
}

export const clearSuggestions = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setSuggestionsSuccess({ results: [] }));
    } catch (error) {
      dispatch(setSuggestionsFailure());
      postMessage({ type: "warning", text: "Clear Suggestions Error: " + error });
    }
  }
}

//this is to parse out the somewhat ugly search results, this should probably be moved server sode
const parseResults = results => {
  if (results && results.value && results.value.length > 0) {
    return results.value.map((result) => {  
      let parsed = result['@search.text'].replace(/<[^>]*>?/gm, '')
      
      return {
        TextMarkup: result['@search.text'],
        TextPlain: parsed,
        ProjectId: result.ProjectId,
        ProjectCode: result.ProjectCode,
        ProjectDates: "",
        ProjectName: result.ProjectName,
        ProjectClientName: result.ProjectClientName,  
        ProjectMarketSegmentName: result.ProjectMarketSegmentName,  
        SecondaryText:  parsed === result.ProjectName ? result.ProjectClientName : result.ProjectName,
        ContractCode: result.ContractCode
      }
    })
  }
  else {
    return [];
  }
}