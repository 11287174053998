import { createSlice } from '@reduxjs/toolkit'


const sliceName = 'post';

export const initialState = {
  loading: false,
  hasErrors: false,
  postId: null,
}

const postSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getPost: state => {
      state.loading = true
    },
    getPostSuccess: (state, { payload }) => {
      state.postId = payload
      state.loading = false
      state.hasErrors = false
    },
    getPostFailure: state => {
      state.loading = false
      state.hasErrors = true
    },
  },
})

export const { getPost, getPostSuccess, getPostFailure } = postSlice.actions
export const postSelector = state => state[sliceName];
export default postSlice.reducer

export function fetchPost(id) {
  return async dispatch => {
    dispatch(getPost())

    try {
      const response = await fetch(
        `https://jsonplaceholder.typicode.com/posts/${id}`
      )
      const data = await response.json()

      dispatch(getPostSuccess(data))
    } catch (error) {
      dispatch(getPostFailure())
    }
  }
}

export function selectPost(postId) {
  return async dispatch => {
    dispatch(getPost())
    try {// just an example, probs unnesecary for something local.
      if (!postId) { throw Error('Invalid post'); }
      dispatch(getPostSuccess(postId));//set the post first, then update the url.
    } catch (error) {
      dispatch(getPostFailure())
    }

  }
}
export function deselectPost(post) {
  return async dispatch => {
    dispatch(getPost())
    try {// just an example, probs unnesecary for something local.
      dispatch(getPostSuccess(null));
    } catch (error) {
      dispatch(getPostFailure())
    }

  }
}