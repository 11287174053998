
import React from 'react'; 
import { makeStyles } from "@material-ui/core/styles";
import { mlAuto, whiteColor, title } from 'components/themeStyle'; 
// import dialogD from "img/DialogD (2).png"; 
import Dialog from "img/Dialog-logo_Blue (1).png"
// import dialogLogo from "img/Dialog-logo_Blue (1).png";
import { Link as RouterLink } from "react-router-dom"; 
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
 
const useStyles = makeStyles(theme => ({
    mlAuto,
    link: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    title: {
        ...title,
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: whiteColor,
        textDecoration: "none"
    },
    inputLabel: {
        color: theme.palette.grey[400],
        '&.Mui-focused': {
            color: theme.palette.grey[400],
        }
    },
    searchIcon: {
        width: "20px",
        height: "20px",
        color: theme.palette.grey[400]
    },
    formControl: {
        margin: "0 !important",
        paddingTop: "7px",
        paddingBottom: "7px"
    },
    loader: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    suggestions: {
        zIndex: 1,
        position: 'absolute',
        border: '1px solid #dadce0',
        backgroundColor: 'white',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        width: 'inherit',
        marginLeft: -9,
        top: 0
    },
    suggestion: {
        paddingLeft: 12.5,
        paddingRight: 12.5,
        paddingTop: 10,
        paddingBottom: 5,
        fontSize: '14px',
        fontWeight: 40,
        "&:hover": {
            backgroundColor: "none"
        }
    },
    suggestionText: {
        paddingLeft: 5
    },
    suggestionHeading: {
        color: 'black'
    },
    suggestionsShown: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px'
    },
    logo: {
        height: 25,
        marginTop: 10,
        marginRight: 10,
        paddingBottom: 2.5
    },
    logoSm: { 
        height: 50,  
        display: 'block'
    },
    logoText: { 
        fontWeight:500,
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "19px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
}));

const LogoDIALOG = props => {
    const classes = useStyles();   
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return ( 
        <>
        {matches &&
            <Link component={RouterLink} to={"/"}>            
                <img src={Dialog} className={classes.logo} alt="DIALOG"  />             
            </Link>  
        }  
        </>
    );
}

export default LogoDIALOG;