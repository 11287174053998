import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  errors: [],
  hasErrors: false,
  searchText: ""
}

const sliceName = 'searchText';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSearchTextInternal: (state, { payload }) => {
      state.searchText = payload
      state.hasErrors = false
    },
    setSearchTextFailure: (state, { payload }) => {
      state.hasErrors = true;
      state.errors.push(payload);
    },
  },
})

export const { setSearchTextInternal, setSearchTextFailure, } = slice.actions
export const searchSelector = state => state[sliceName];
export default slice.reducer

//also triggers suggestions fetching
export const setSearchText = (searchText) => {
  return async dispatch => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setSearchTextInternal(searchText)) 
    } catch (error) {
      dispatch(setSearchTextFailure(error + ""))
    }
  }
}