import { createSlice } from '@reduxjs/toolkit';
import searchApi from '../search/search-service';
import { postMessage } from 'store/slices/messages/messages';
import { fetchSiblingProjects } from './siblingProjects';
import { GetToken } from 'CustomAuthProvider';
import { getProjectEdits } from './selectedProjectEdits';

export const initialState = {
  hasErrors: false,
  loading: false,
  selectedProject: null,
  elapsedTime: null
}

const sliceName = 'selectedProject';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    editSelectedProject: (state, { payload }) => {
      const { ...editProperties } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.selectedProject = { ...state.selectedProject, ...editProperties };
    },
    setSelectedProjectStarting: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setSelectedProjectSuccess: (state, { payload }) => {
      const { elapsedTime, selectedProject } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.selectedProject = selectedProject;
      state.elapsedTime = elapsedTime;
    },
    setSelectedProjectFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setSelectedProjectStarting, setSelectedProjectSuccess, setSelectedProjectFailure, editSelectedProject } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer

export const getProject = (identifier) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setSelectedProjectStarting());

      const tokenPromise = GetToken();
      const token = await tokenPromise;

      const results = await searchApi.getDocumentById(identifier, token.idToken);

      //const parsedResults = parseSearchResults(results);

      //console.log(parsedResults)
      dispatch(setSelectedProjectSuccess({ selectedProject: results.primary }));


      if (results.primary) {
        dispatch(getProjectEdits(identifier));
        dispatch(fetchSiblingProjects(results.primary))
      }
    } catch (error) {
      dispatch(setSelectedProjectFailure());
      postMessage({ type: "warning", text: "Fetching Project Error: " + error });
    }
  }
}

export const clearProject = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setSelectedProjectSuccess([]));
    } catch (error) {
      dispatch(setSelectedProjectFailure());
      postMessage({ type: "warning", text: "Clearing Project Error: " + error });
    }
  }
}
