import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import workStyle from "./workStyle.js";

const useStyles = makeStyles(workStyle);

const SectionWork = props => {
  const classes = useStyles();
  const [ideaText, setIdeaText] = React.useState("");

  const handleIdeaTextChange = event => {
    setIdeaText(event.target.value || "");
  }
   
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={8} md={8}>
          <Typography variant="h2" className={classes.title}>Can't find something?</Typography>
          <Typography variant="h4" className={classes.description}>
            Let us know how to make this web app more user friendly. Send us your ideas
            for how to improve below:
          </Typography>
          <form>
            <GridContainer>
              <CustomInput
                labelText="Your Idea"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  value:ideaText,
                  onChange:handleIdeaTextChange
                }}

              />
              <GridItem
                xs={12}
                sm={4}
                md={4}
                className={classes.mrAuto + " " + classes.mlAuto}
              >
                <Button color="primary" href={"mailto:devhub@dialogdesign.ca;kbachmann@dialogdesign.ca?subject=Project%20Explorer%20Idea&body=" + encodeURIComponent(ideaText)}>Send Message</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SectionWork;