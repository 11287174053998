import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(theme => {
  return ({
    root: {
      color: theme.palette.primary,
      height: 2,
    },
    thumb: {
      height: 16,
      width: 16,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      marginTop: -6,
      marginLeft: -7,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% - 4px)',
    },
    track: {
      height: 2,
      borderRadius: 0,
      color: theme.palette.primary
    },
    rail: {
      height: 2,
      borderRadius: 0,
      color: '#3c4858'
    },
  })
});

const ContinuousSlider = props => {
  const classes = useStyles(props);
  const [value, setValue] = React.useState(props.value || 30);
  const id = props.id || ("continuous-slider" + (Math.random() * 1000000).toFixed());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div >
      <Typography id={id} gutterBottom>
        Volume
      </Typography>
      <Slider
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          active: classes.active,
          valueLabel: classes.valueLabel,
          track: classes.track,
          rail: classes.rail,
        }}
        value={value}
        valueLabelDisplay="auto"
        onChange={handleChange}
        aria-labelledby={id} />
    </div>
  );
}

export default ContinuousSlider;