import React from "react";
import { makeStyles } from '@material-ui/core/styles';
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "img/image_placeholder.jpg";
import defaultAvatar from "img/placeholder.jpg";


const useStyles = makeStyles(theme => {
  return ({
      fileInput: {
          textAlign: 'center',
          display: 'inline-block',
          marginBottom: '9px',
          // input[type="file"] {
          //   display: none;
          // }
      },
      thumbnail:{
        display: 'inline-block',
        marginBottom: '10px',
        overflow: 'hidden',
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '360px',
        boxShadow: `0 5px 15px -8px rgba(0, 0, 0, 0.24),
          0 8px 10px -5px rgba(0, 0, 0, 0.2)`,
      },
      imgCircle: {
        borderRadius: '50%',
        maxWidth: '100px',
      },
      img:{
        maxHeight:'100%',
        width:'100%',
        verticalAlign:'middle',
      },
      actualFileInput:{
        display:'none',
      },
      thumb: {
          height: 16,
          width: 16,
          backgroundColor: '#fff',
          border: '1px solid currentColor',
          marginTop: -6,
          marginLeft: -7,
          '&:focus, &:hover': {
              boxShadow: 'inherit',
          },
      },

  })
});


export default function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );
  let fileInput = React.createRef();

  const classes = useStyles(props);

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <div className={classes.fileInput + " " + classes.textCenter}>
      <input className={classes.actualFileInput} type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={classes.thumbnail + (avatar ? " "+classes.imgCircle : "")}>
        <img className={classes.img} src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              Change
            </Button>
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times" /> Remove
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};
