
import getFetchOptions from 'store/utils/fetchOptions';
const baseAPI = '/api/';

const getDocumentById = async (id, idToken) => {
  try {

    const documentType = "projects-index" 
    
    const response = await fetch(`${baseAPI}/search/documents/${documentType}/id/` + id,
      getFetchOptions('GET', 'application/json', null, true, idToken))
      .then(response => {
        if (!response.ok) {
          throw (Error(response.status + " " + response.statusText));
        }
        return response;
      });
    const parsedResponse = await response.json();
    return parsedResponse;
  }
  catch (err) {
    throw err;
  };
}

const getSearch = async (search, index, facets, filter, idToken, top, skip, highlight, searchFields, scoringProfile,) => {
  try {

    // let searchString = `?search=${search}&index=${index}&facets=${facets}&searchFields=${searchFields || ""}&filter=${filter || ""}&highlight=${highlight}&top=${top || 10}&skip=${skip || 0}`;
    // if (scoringProfile) { searchString = searchString + "&scoringProfile=" + scoringProfile; }
 
    const body = {
      search, index, facets, filter, top, skip, highlight, searchFields, scoringProfile,
    }
    const response = await fetch(`${baseAPI}/search`,// + searchString,
      getFetchOptions('POST', 'application/json', body, true, idToken))
      .then(response => {
        if (!response.ok) {
          throw (Error(response.status + " " + response.statusText));
        }
        return response;
      });
    const parsedResponse = await response.json();
    return parsedResponse;
  }
  catch (err) {
    throw err;
  };
};

const getSuggestions = async (query, index, suggesterName, searchFields, select, top, idToken, filterString) => {
  console.log(filterString);
  try {
    const response = await fetch(
      `${baseAPI}/suggest?search=${query}&index=${index}&suggesterName=${suggesterName}&searchFields=${searchFields}&select=${select}&top=${top}${filterString ? "&filter=" + filterString : ""}`,
      getFetchOptions('GET', 'application/json', null, true, idToken)
    );
    if (!response.ok) {
      throw Error(response.status + " " + response.statusText);
    }
    const parsedResponse = await response.json();
    return parsedResponse;
  }
  catch (err) {
    throw (err)
  };
};

const getMoreLikeThis = async (index, documentId, searchFields, select, filter, top, idToken) => {
  try {
    const response = await fetch(
      `${baseAPI}/morelikethis?index=${index}&documentId=${documentId}&searchFields=${searchFields}&select=${select}&filter=${filter}&top=${top}`,
      getFetchOptions('GET', 'application/json', null, true, idToken)
    );
    if (!response.ok) {
      throw Error(response.status + " " + response.statusText);
    }
    const parsedResponse = await response.json();
    return parsedResponse;
  }
  catch (err) {
    throw (err)
  };
};

const searchService = {
  getSearch,
  getSuggestions,
  getMoreLikeThis,
  getDocumentById,
  getFacet(index, facet, idToken) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${baseAPI}/facets?index=${index}&facet=${facet}`,
          getFetchOptions('GET', 'application/json', null, true, idToken))
          .then(response => {
            if (!response.ok) {
              reject(Error(response.status + " " + response.statusText));
            }
            return response;
          })
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => reject(err));
      }
      catch (err) {
        reject(err)
      };
    })
  },

};

//|| "ProjectClientName eq 'CALGARY AIRPORT AUTHORITY' or ProjectClientName eq 'KELOWNA INTERNATIONAL AIRPORT'",
export function getFilterString(facetableFilters, activeOnly, yearsFilter,) {
  const currentDate = new Date().toISOString();
  let filterString = "";

  Object.keys(facetableFilters).forEach(key => {
    const currentFilter = facetableFilters[key];
    if (currentFilter.selected.length > 0) {
      if (filterString !== "") {
        filterString += " and ";
      }
      let currentFilterString = "(";
      currentFilter.selected.forEach(selected => {
        currentFilterString += `${key} eq '${selected.value}' or `;
      });
      if (currentFilterString.endsWith(" or ")) {
        currentFilterString = currentFilterString.substring(0, currentFilterString.length - 4);
      };
      currentFilterString += ")";

      filterString += currentFilterString;
    }
  });
  if (activeOnly) {
    if (filterString !== "") {
      filterString += `and ProjectStartDate lt ${currentDate} and ProjectFinishDate gt ${currentDate}`;
    } else {
      filterString += `ProjectStartDate lt ${currentDate} and ProjectFinishDate gt ${currentDate}`;
    }
  }
  if (yearsFilter && yearsFilter.selected.length > 0) {

    if (filterString !== "") {
      filterString += ` and `;
    }
    filterString += '(';
    yearsFilter.selected.forEach((selected, index) => {
      if (index > 0) {
        filterString += ' or ';
      }
      filterString += getDateRange(selected.value);

    });
    filterString += ')';
  }
  return filterString;
}

//function to get a date range for a year
function getDateRange(year) {
  const startDate = new Date(year, 0, 1).toISOString();
  const endDate = new Date(year, 11, 31).toISOString();
  return `(ProjectStartDate lt ${endDate} and ProjectFinishDate gt ${startDate})`;
}

export default searchService;