import React from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
//import { IoMdSquare } from 'react-icons/io';



const Carousel = props => {
    const arrowStyles = {
        position: 'absolute',
        color: '#00000080',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 35,
        height: 35,
        cursor: 'pointer',
    };
    const indent = 30;
    // const indicatorStyles = {
    //     display: 'inline-block',
    //     margin: '0 8px',
    //     cursor: 'pointer',
    //     verticalAlign: 'center',
    // };
    return (
        <ResponsiveCarousel
            swipeable={true}
            emulateTouch={true}
            infiniteLoop={true}
            dynamicHeight={false}
            showArrows={false}
            showThumbs={true}
            showStatus={false}
            transitionTime={600}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <ArrowBackIos
                        style={
                            {
                                ...arrowStyles,
                                left: indent,
                            }}
                        onClick={onClickHandler}
                    />
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <ArrowForwardIos
                        style={
                            {
                                ...arrowStyles,
                                right: indent,
                            }}
                        onClick={onClickHandler}
                    />
                )
            }
        >
            {props.children}
        </ResponsiveCarousel >
    )
};

export default Carousel;