import React from "react";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from "react-redux";
import { selector as selectedProjectSelector } from 'store/slices/projectPage/selectedProject';
import { useSelector } from "react-redux";
import { Grid, IconButton } from "@material-ui/core";
import { editTags } from "store/slices/projectPage/tagsEditing";
import { MdCancel, MdSave } from "react-icons/md";

export default function ProjectTags({ tags, onCancel, onSave }) {
  const dispatch = useDispatch();
  const { selectedProject } = useSelector(selectedProjectSelector);
  const [tagsValue, setTagsValue] = React.useState(tags);
  const [inputValue, setInputValue] = React.useState("");

  console.log(tagsValue)
  return (
    <Grid container spacing={1}>
      <Grid container item style={{ width: 'auto' }}>
        <Grid item style={{ display: 'flex', alignItems: 'center' }} >
          <IconButton aria-label="cancel" size="small" onClick={onCancel} >
            <MdCancel />
          </IconButton>
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center' }} >
          <IconButton aria-label="save" size="small"
            onClick={() => {
              dispatch(editTags(selectedProject.ProjectId, inputValue ? tagsValue.concat(inputValue) : tagsValue));
              onSave();
            }}
          >
            <MdSave />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <Autocomplete
          fullWidth

          multiple
          id="tags-filled"
          options={tags}//{top100Films.map((option) => option.title)}
          defaultValue={tags}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {setInputValue(newInputValue)}}
          value={tagsValue}
          onChange={(event, newValue) => { setTagsValue(newValue); }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} autoFocus variant="standard" placeholder={`Add new word or phrase (can include spaces, hit Enter to end).`} />
          )}
        />
      </Grid>

    </Grid>
  );
}
