import React from "react";
import { SvgIcon } from '@material-ui/core';

export function ContractIconOutlined(props) {
    return (
        <SvgIcon {...props}>
            <path  d="M 15.017 0.018 C 15.616 0.004 16.192 0.226 16.611 0.663 L 20.526 4.6 C 20.943 5.003 21.182 5.599 21.192 6.194 L 21.196 21.773 C 21.195 22.999 20.147 23.988 18.949 23.982 L 5.444 24 C 4.227 24.003 3.197 23.009 3.194 21.769 L 3.2 2.257 C 3.181 1.017 4.204 0.006 5.429 0 Z M 4.703 2.249 L 4.705 21.756 C 4.712 22.174 5.041 22.483 5.431 22.494 L 18.959 22.497 C 19.342 22.505 19.69 22.16 19.693 21.76 L 19.691 7.497 L 14.811 7.513 C 14.196 7.512 13.698 7.064 13.701 6.367 L 13.704 1.48 L 5.431 1.477 C 5.042 1.486 4.7 1.857 4.703 2.249 Z M 15.187 5.999 L 19.688 5.982 C 19.687 5.913 19.614 5.79 19.471 5.641 C 19.327 5.495 15.551 1.699 15.551 1.699 C 15.432 1.585 15.359 1.509 15.2 1.508 Z" />
            <path  d="M 6.572 3.014 L 11.832 3.014 C 12.016 3.017 12.183 3.163 12.186 3.388 L 12.191 4.136 C 12.19 4.358 12.018 4.488 11.825 4.49 L 6.578 4.497 C 6.365 4.493 6.186 4.357 6.185 4.095 L 6.185 3.375 C 6.188 3.159 6.362 3.016 6.572 3.014 Z" />
            <path  d="M 6.56 6.056 L 11.82 6.056 C 12.004 6.058 12.171 6.204 12.174 6.43 L 12.181 7.177 C 12.18 7.399 12.006 7.529 11.814 7.53 L 6.568 7.538 C 6.353 7.533 6.176 7.398 6.173 7.137 L 6.173 6.416 C 6.176 6.201 6.351 6.056 6.56 6.056 Z" />
            <path  d="M 7.691 17.99 L 8.274 17.99 L 8.765 17.662 C 8.765 17.662 9.469 15.523 9.623 15.076 C 9.776 14.627 10.225 14.326 10.688 14.313 C 11.177 14.31 11.622 14.617 11.765 15.079 L 12.408 17.042 C 13.335 16.275 14.938 16.57 15.505 17.7 C 15.657 17.909 15.768 17.986 15.995 17.996 L 16.706 17.996 C 17.108 18.009 17.422 18.328 17.435 18.755 C 17.453 19.17 17.104 19.5 16.695 19.509 C 16.287 19.517 15.983 19.506 15.983 19.506 C 15.194 19.507 14.534 19.072 14.186 18.379 C 14.043 18.145 13.917 18.102 13.704 18.077 C 13.518 18.068 13.357 18.131 13.21 18.367 L 12.869 19.086 C 12.748 19.352 12.485 19.52 12.168 19.519 C 11.838 19.475 11.579 19.272 11.435 18.94 L 10.698 16.591 L 10.199 18.097 C 9.941 18.958 9.148 19.5 8.264 19.501 L 7.681 19.509 C 7.284 19.504 6.949 19.178 6.952 18.755 C 6.943 18.338 7.279 18.002 7.691 17.99 Z" />
        </SvgIcon>
    );
}

export default ContractIconOutlined;
