/*eslint-disable*/ import React, { useRef, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  Typography
} from "@material-ui/core";
// @material-ui/icons
import {
  Favorite,
} from "@material-ui/icons";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/WelcomeHeaderLinks.js";
import HeaderLinksResponsive from "components/Header/WelcomeHeaderLinksResponsive.js";
// Sections for this page
//styling
import {
  container,
  title,
  main,
  blackColor,
  whiteColor,
  mainRaised,
} from "components/themeStyle.js";
import image from "img/bg7.jpg";
import { hexToRgb } from "components/themeStyle";
import AccountCircle from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles(theme => ({
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2",
  },
  title: {
    ...title,
    fontSize: "13.7em",
    color: whiteColor,
    letterSpacing: "14px !important",
    fontWeight: "700"
  },
  subTitle: {
    fontSize: "2.25rem",
    marginTop: "0",
    marginBottom: "8px"
  },
  description: {
    fontSize: "1.125rem",
    marginTop: "0",
    marginBottom: "8px"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: whiteColor,
    padding: "0 15px",
    padding: theme.spacing(5),
    width:'260px',
    maxWidth: "880px",
    borderRadius: theme.shape.borderRadius,
    background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"

  },
  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  accountIcon: {
    fontSize: '90px'
  }
}));

export default function ErrorPage({ ...rest }) {
  const classes = useStyles();
  const scrollRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const executeScroll = () => {
    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };


  return (
    <div>
      <Header
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="info" />}
        linksResponsive={<HeaderLinksResponsive />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
        {...rest}
      />

      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.contentCenter}>
          <Grid container>
            <Grid item md={12}>
              <AccountCircle className={classes.accountIcon} />
              <Typography variant='h2' component='h1' className={classes.subtitle}>Signing in...</Typography>
              <CircularProgress size={40} />
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://www.dialogdesign.ca/"
                    target="_blank"
                    className={classes.block}
                  >
                    DIALOG
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://dash.dialogdesign.ca/content/18674"
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://dash.dialogdesign.ca/content/18675"
                    target="_blank"
                    className={classes.block}>
                    Blog
                  </Link>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> by DIALOG's {" "}
              <Link
                href="https://dash.dialogdesign.ca/content/18674"
                target="_blank"
              >
                DevHub
              </Link>{" "}
              .
            </div>
          </div>
        }
      />
    </div>
  );
}










