import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import blog6 from "img/examples/blog6.jpg";
import blog8 from "img/examples/blog8.jpg";
import blog7 from "img/examples/blog7.jpg";

import sectionSimilarStoriesStyle from "./sectionSimilarStoriesStyle.js";

const useStyles = makeStyles(sectionSimilarStoriesStyle);

export default function SectionSimilarStories() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <Typography variant='h2' className={classes.title + " " + classes.textCenter}>
              Similar Stories
            </Typography>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <Link href="#pablo">
                      <img src={blog6} alt="..." />
                    </Link>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog6 + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Info>
                      <Typography variant='h6'>ENTERPRISE</Typography>
                    </Info>
                    <Typography variant='h4' className={classes.cardTitle}>
                      <Link href="#pablo">
                        Autodesk looks to future of 3D printing with Project
                        Escher
                      </Link>
                    </Typography>
                    <Typography variant='body1' className={classes.description}>
                      Like so many organizations these days, Autodesk is a
                      company in transition. It was until recently a traditional
                      boxed software company selling licenses.
                      <Link href="#pablo"> Read More </Link>
                    </Typography>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <Link href="#pablo">
                      <img src={blog8} alt="..." />
                    </Link>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog8 + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Success>
                      <Typography variant='h6'>STARTUPS</Typography>
                    </Success>
                    <Typography variant='h4' className={classes.cardTitle}>
                      <Link href="#pablo">
                        Lyft launching cross-platform service this week
                      </Link>
                    </Typography>
                    <Typography variant='body1' className={classes.description}>
                      Like so many organizations these days, Autodesk is a
                      company in transition. It was until recently a traditional
                      boxed software company selling licenses.
                      <Link href="#pablo"> Read More </Link>
                    </Typography>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <Link href="#pablo">
                      <img src={blog7} alt="..." />
                    </Link>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog7 + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Danger>
                      <Typography variant='h6'>
                        <TrendingUp /> ENTERPRISE
                      </Typography>
                    </Danger>
                    <Typography variant='h4' className={classes.cardTitle}>
                      <Link href="#pablo">
                        6 insights into the French Fashion landscape
                      </Link>
                    </Typography>
                    <Typography variant='body1' className={classes.description}>
                      Like so many organizations these days, Autodesk is a
                      company in transition. It was until recently a traditional
                      boxed software company selling licenses.
                      <Link href="#pablo"> Read More </Link>
                    </Typography>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
