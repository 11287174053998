import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
// @material-ui/icons
// core components  
import Button from "components/CustomButtons/Button.js";
import ArrowBackIosOutlined from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlined from '@material-ui/icons/ArrowForwardIosOutlined';
import ItemsCarousel from 'react-items-carousel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  sectionSlider: {
    padding: "10px 0"
  },
  navigationWrapper: {
    position: 'relative',
    marginBottom: 10
  },
  projectsProgress:{
    margin: theme.spacing(4)
  }
}));

const RelatedItemsCarousel = props => {
  const classes = useStyles(props);
  //const items = props.items || [];
  const [activeItemIndex, setActiveItemIndex] = React.useState(0);
  const chevronWidth = 0;
  
  const theme = useTheme(); 
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const lg = useMediaQuery(theme.breakpoints.down('xl')); 
  
  const children = props.loading ? [<CircularProgress key={"wortwortwort"} size={50} className={classes.projectsProgress} />] : props.children;
  
  return (
    <div className={classes.sectionSlider} >
      <div className={classes.navigationWrapper}>

        <div style={{ padding: `0 ${chevronWidth}px` }}>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={xs ? 1 : sm ? 3 : md ? 6 : lg ? props.numberOfCards : 2}
            gutter={12.5}
            leftChevron={<Button justIcon round color="white"><ArrowBackIosOutlined fontSize="small" /></Button>}
            rightChevron={<Button justIcon round color="white"><ArrowForwardIosOutlined fontSize="small" /></Button>}
            outsideChevron
            chevronWidth={chevronWidth}
          >
            {children}
          </ItemsCarousel>
        </div>
      </div>
    </div>
  );
}

export default RelatedItemsCarousel;

