import { createSlice } from '@reduxjs/toolkit';
import projectApi from './project-edit-service';
import { postMessage } from 'store/slices/messages/messages';
//import { push } from 'connected-react-router';
import { GetToken } from 'CustomAuthProvider';
import { editSelectedProject } from './selectedProject';

export const initialState = {
  hasErrors: false,
  loading: false,
  results: [],
  elapsedTime: null,
}

const sliceName = 'directoryEditing';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setDirectoryEditStarting: state => {
      state.hasErrors = false;
      state.loading = true;
      state.results = [];
    },
    setDirectorysEditSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
    },
    setDirectoryEditFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setDirectoryEditStarting, setDirectorysEditSuccess, setDirectoryEditFailure } = slice.actions
export const directoryEditingSelector = state => state[sliceName];
export default slice.reducer

export const editDirectory = (projectId, directory) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setDirectoryEditStarting());

      const token = await GetToken();

      const results = await projectApi.editProject(token.idToken, projectId, "NetworkDirectory", directory);
      
      dispatch(setDirectorysEditSuccess(results));
      dispatch(editSelectedProject({ NetworkDirectory_current: directory }))
    } catch (error) {
      dispatch(setDirectoryEditFailure());
      dispatch(postMessage({ type: "warning", text: "Directory folder editing error: " + error }));
    }
  }
}
