import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';


const useStyles = makeStyles(theme => {
    return ({
        root: {
            color: theme.palette.primary,
            height: 2,
        },
        thumb: {
            height: 16,
            width: 16,
            backgroundColor: '#fff',
            border: '1px solid currentColor',
            marginTop: -6,
            marginLeft: -7,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% - 4px)',
        },
        track: {
            height: 2,
            borderRadius: 0,
            color: theme.palette.primary
        },
        rail: {
            height: 2,
            borderRadius: 0,
            color: '#3c4858'
        },
    })
});


function valuetext(value) {
    return `${value}°C`;
}

const RangeSlider = props => {
    const classes = useStyles();
    const [value, setValue] = React.useState([20, 37]);
    const id = props.id || ("range-slider" + (Math.random() * 1000000).toFixed());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Typography id={id} gutterBottom>
                Temperature range
        </Typography>
            <Slider
                classes={{
                    root: classes.root,
                    thumb: classes.thumb,
                    active: classes.active,
                    valueLabel: classes.valueLabel,
                    track: classes.track,
                    rail: classes.rail,
                }}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby={id}
                getAriaValueText={valuetext}
            />
        </div>
    );
};
export default RangeSlider; 