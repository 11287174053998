import { CircularProgress, Grid, List, ListItem, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from 'react-redux';
import React from "react"
import { selector as facetsSelector } from 'store/slices/search/searchFilters';
import { filterSelected } from "store/slices/search/searchFilters";
import { getSearch } from "store/slices/search/searchResults";

function Filter({ id, name, facets, selected }) {
    const dispatch = useDispatch();

    return (
        <>
            {facets &&
                <Autocomplete
                    fullWidth
                    multiple
                    id={id}
                    limitTags={1}
                    options={facets}
                    getOptionLabel={(option) => option.value + (option.count !== undefined ? ' (' + option.count + ')' : '')}
                    value={selected}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={(event, newValue) => {
                        console.log(newValue)
                        dispatch(filterSelected({ facetId: id, selected: newValue }))
                        dispatch(getSearch())
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={name}
                            placeholder={name}
                        />
                    )}
                />
            }
            {!facets &&
                <CircularProgress size={20} />
            }
        </>
    )
}



export function FacetsSection() {
    const facets = useSelector(facetsSelector);

    return (
        <Grid container spacing={3}>
            {Object.entries(facets).map(([key, filterInfo]) => (
                <Grid key={key} item xs={2}>
                    <Filter id={key} name={filterInfo.name} facets={filterInfo.facets} selected={filterInfo.selected} />
                </Grid>
            ))}
        </Grid>
    )
}

export function FacetsVerticalList() {
    const facets = useSelector(facetsSelector);
    console.log(facets)

    return (
        <List component="div" disablePadding>
            {Object.entries(facets).map(([key, filterInfo]) => (
                <ListItem>
                    <Filter id={key} name={filterInfo.name} facets={filterInfo.facets} selected={filterInfo.selected} />
                </ListItem>
            ))}
        </List>
    )
}