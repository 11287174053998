let idToken = null;


const getFetchOptions = (method, contentType, body, includeAuth, customIdToken) => {
  if (typeof (includeAuth) === 'undefined') {
    includeAuth = true;
  }
  if (!method) { method = 'GET' }
  const opt = {};
  opt.method = method;
  if (contentType === 'application/json' && body !== null && typeof (body) === 'object') {
    body = JSON.stringify(body);
  }
  if (body) { opt.body = body }

  opt.headers = {};
  opt.headers['X-FP-API-KEY'] = 'iphone'; //it can be iPhone or your any other attribute
  if (contentType) { opt.headers['content-type'] = contentType }
  let sendToken = idToken;
  if (customIdToken) { sendToken = customIdToken; }
  if (includeAuth) {
    if (sendToken && sendToken.rawIdToken) {
      opt.withCredentials = true;
      opt.credentials = 'include';
      opt.headers['X-CUSTOM-AUTHORIZATION'] = 'Bearer ' + sendToken.rawIdToken;
    }
    else
    {
      console.log('warning auth selected but idtoken invalid')
    }
  }
  //console.log('fetch options:')
  //console.log(opt);
  return opt;
};
export default getFetchOptions;