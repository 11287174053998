import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import clsx from "clsx";

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 0px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const styles = theme => ({
    // margin: {
    //     margin: theme.spacing.unit * 2
    // },
    customBadge: {
        backgroundColor: props => props.color === "active" ? theme.palette.success.main : (props.color === "inactive" ? theme.palette.grey[400] : props.color),
        color: "white",
        border: `1px solid white`,
        borderWidth: props => props.borderWidth ? props.borderWidth : "1px",
    },

    dot: {
        "& .MuiBadge-dot": {
            height: props => props.size ? props.size : 8,
            width: props => props.size ? props.size : 6,
            borderRadius: props => props.size ? props.size / 2 : 3,

        }
    },
});

function MuiBadgeUnstyled({
    className,//combine with our dot styling override
    classes,
    children,
    color,//we pass this on, unless we're it's set to "active" or "inactive"
    borderWidth,//don't pass this on, just for the styling
    size,//don't pass this on, just for the styling
    disableRipple, //if this is true it won't use the ripple effect for this badge
    ...rest
}) {
    return (
        <div>
            {(color === "active" && !disableRipple) &&
                <StyledBadge
                    className={clsx(classes.dot, className)}
                    classes={{ badge: classes.customBadge, }}
                    showZero
                    color={(color === "active" || color === "inactive") ? undefined : color}//if it's one of the two color states we're adding don't pass it to the base component
                    {...rest}
                >
                    {children}
                </StyledBadge>

            }
            {(color !== "active" || disableRipple) &&
                <Badge
                    className={clsx(classes.dot, className)}
                    classes={{ badge: classes.customBadge, dot: classes.dot }}
                    showZero
                    color={(color === "active" || color === "inactive") ? undefined : color}//if it's one of the two color states we're adding don't pass it to the base component
                    {...rest}
                >
                    {children}
                </Badge>
            }

        </div>
    );
}

MuiBadgeUnstyled.propTypes = {
    classes: PropTypes.object.isRequired
};

export const MuiBadge = withStyles(styles)(MuiBadgeUnstyled);
export default MuiBadge;