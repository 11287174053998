/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import ListPost from './ListPost.jsx';

import { useSelector } from 'react-redux'
import { postsSelector } from 'store/slices/posts/posts';

const useStyles = makeStyles(theme => ({

}));

const PostsList = props => {
  const { posts, loading, hasErrors } = useSelector(postsSelector);

  return (
    <>
      {Object.values(posts)
        .map(post => (
          <ListPost onClick={() => console.log('click')} key={post.id} post={post} />
        ))}
    </>
  );
}

export default PostsList;