/*eslint-disable*/
import React, { useState } from "react";
import Box from '@material-ui/core/Box';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// core components
import { Card as MatCard } from "@material-ui/core";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from "@material-ui/core/CardContent";
import Small from 'components/Typography/Small';

import { useDispatch, useSelector } from 'react-redux'
import { deselectPost, postSelector } from 'store/slices/posts/post';
import { postsSelector } from 'store/slices/posts/posts';

const useStyles = makeStyles(theme => ({
  selectedPost: {
    margin: '10px 0px',
    borderRadius: '5px',
    border: '1px solid #7777771D',
    cursor: 'pointer',

  },
  stick: {
    position: 'sticky',
    top: '0',
  }
}));

const SelectedPost = props => {
  console.log('render post');
  const dispatch = useDispatch();
  const { postId } = useSelector(postSelector);
  const { posts, postsloading } = useSelector(postsSelector);

  // const location = useSelector(locationSelector);
  // console.log('locaiton')
  // console.log(location)

  let hasErrors = false;
  let post = null;
  // let hasErrors = false;
  if (postId && posts[postId]) {
    post = posts[postId];
  } else {
    hasErrors = true;
  }

  const classes = useStyles(props);
  const closePost = () => {
    dispatch(deselectPost())
  }
  return (
    <div className={classes.stick}>
      {(!post || !post.id) && !hasErrors && !postsloading &&
        <Typography variant="h6">
          Please select a post.
        </Typography>
      }
      {hasErrors &&
        <Typography variant="h6">
          Warning, error with the current post.
        </Typography>
      }
      {(!post && postsloading) &&
        <Typography variant="h6">
          Loading...
        </Typography>
      }
      {(post && post.id && !postsloading) &&
        <MatCard
          className={classes.selectedPost}
        >
          <CardActionArea onClick={closePost}>
            <CardContent>
              <Typography variant='h5' gutterBottom>
                {post.title.toUpperCase()}
              </Typography>
              <Typography variant='body1'>
                {post.body}
              </Typography>
            </CardContent>
          </CardActionArea>
        </MatCard>

      }

    </div>
  );
}

export default SelectedPost;