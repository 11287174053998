import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
} from "components/themeStyle.js";

const contractStyle = theme => ({
  infoArea: { 
    margin: "0 auto", 
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  primary: {
    color: primaryColor[0]
  },
  warning: {
    color: warningColor[0]
  },
  danger: {
    color: dangerColor[0]
  },
  success: {
    color: successColor[0]
  },
  info: {
    color: infoColor[0]
  },
  rose: {
    color: roseColor[0]
  },
  gray: {
    color: grayColor[0]
  },
  descriptionWrapper: {
    color: grayColor[0],
    overflow: "hidden"
  },
  selectedTitle: {  
    margin: "0 0 0.875rem !important",
    minHeight: "unset",
    "& a": { 
      textDecoration: 'none'
    },
    color: '#4d5156',
    fontWeight: 400,
  }, 
  title: {  
    margin: "1.75rem 0 0.875rem !important",
    minHeight: "unset",
    "& a": { 
      textDecoration: 'none'
    },
    fontWeight: 400,
    color: '#4d5156'
  }, 
  description: { 
    overflow: "hidden",
    marginTop: "0px",
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 400
  },
  descriptionStrong: {
    overflow: "hidden",
    marginTop: 15,
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    marginBottom: 5,
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 600
  },
  gridDescription: {
    overflow: "hidden",
    marginTop:  0,
    marginBottom: 5,
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 400
  },
  mainLabel: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    "& p": {
      color: '#4d5156',
      fontSize: "12px"
    },
    color: "#4d5156",
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: 0,
    letterSpacing: 'normal',
    textTransform: 'none'
  },
  mainLabelHeader: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    "& p": {
      color: '#4d5156' 
    },
    color: "#4d5156", 
    fontWeight: 400,
    marginBottom: 0,
    letterSpacing: 'normal',
    textTransform: 'none'
  },
  smaller: {
    overflow: "hidden",
    marginTop: "5px",
    "& p": {
      color: '#4d5156',
      fontSize: "12px"
    },
    color: "#4d5156",
    fontSize: "12px",
    fontWeight: 400, 
    marginBottom: 0,
    letterSpacing: 'normal',
    textTransform: 'none'
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  },
  icon: {
    fontSize: '1.3em',
    marginBottom: '-3px',
    marginRight: '5px'
  },
  iconSmall: {
    fontSize: '1.3em',
    marginBottom: '-5px',
    marginRight: '5px',
    color: "#4d5156",
  },
  section: {
    paddingTop: 0
  },
  link: {
    fontSize: "14px",
    textDecoration: "none",
    fontWeight: 400,
    marginRight: 12.5
  },
  main: {
    marginTop: 150
  },
  paper: {
    padding: 25, 
    backgroundColor: 'transparent',
    borderRadius: 8
  },
  resultItem: { 
    padding: 25
  },
  
  /* styles for '...' */ 
  blockWithText: {
    /* hide text if it more than N lines  */
    overflow: 'hidden',
    /* for set '...' in absolute position */
    position: 'relative', 
    /* use this value to count block height */
    lineHeight: '1.2em',
    /* max-height = line-height (1.2) * lines max number (3) */
    maxHeight: '3.6em', 
    /* fix problem when last visible word doesn't adjoin right side  */
    textAlign: 'justify',  
    /* place for '...' */
    marginRight: '-1em',
    paddingRight: '1em'
  } 

});

export default contractStyle;
