import { title, 
  blackColor,
  primaryColor,
  hexToRgb,
  infoColor,
  whiteColor,
  roseColor,
  description,
  grayColor,
   
 } from "components/themeStyle.js";  

const productStyle = {
   
  section: {
    padding: "90px 0", 
    textAlign: "left"
  },
  customExpandPanel: {
    maxHeight: "273px",
    overflowY: "scroll",
    "&  label": {
      display: "block"
    },
    paddingLeft: 15
  },
  checkboxAndRadio: {
    position: "relative",
    display: "block",
    marginTop: "10px",
    marginBottom: "10px"
  },
  checkboxAndRadioHorizontal: {
    position: "relative",
    display: "block",
    "&:first-child": {
      marginTop: "10px"
    },
    "&:not(:first-child)": {
      marginTop: "-14px"
    },
    marginTop: "0",
    marginBottom: "0"
  },
  checked: {
    color: primaryColor[0] + "!important"
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px"
  },
  disabledCheckboxAndRadio: {
    "& $checkedIcon,& $uncheckedIcon,& $radioChecked,& $radioUnchecked": {
      borderColor: blackColor,
      opacity: "0.26",
      color: blackColor
    }
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: { 
    overflow: "hidden",
    marginTop: "0px",
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 400
  },
  headerStyle: {
    color: "Black",
    fontWeight: 400
  },
  paginationSection: {
    marginTop: 30
  },
  paper: {
    margin: 0,
    padding: 15,  
  },
  sidebarSection: { 
    paddingLeft: 25
  },
  link: {
    fontSize: "14px",
    textDecoration: "none",
    fontWeight: 400,
    marginRight: 12.5
  },
  main: {
    marginTop: 150
  }, 
  sectionSlider: {
    padding: "10px 0"
  },
  arrow: {
    width: "30px",
    height: "30px",
    position: "absolute",    
    top: "60%",
    transform: "translateY(-50%)",
    webkitTransform: "translateY(-50%)",
    fill: "Black",
    cursor: "pointer"
  },  
  arrowLeft: {
    left: "-10px"
  },  
  arrowRight: {
    left: "auto",
    right: "5px"
  },  
  arrowDisabled: {
    fill: "Gray"
  },
  navigationWrapper: {
    position: 'relative',
    marginBottom: 10
  },
  sliderItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",    
    height: "200px", 
    width: "100%"
  }, 
  keenSlider: {
    height: "250px"
  },
  root: { 
    display: "flex",
    alignItems: "center",
    justifyContent: "center",    
    height: "200px", 
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  }, 
  pos: {
    marginBottom: 12,
  },
  selectedTitle: {
    margin: "0rem 0 0 !important",
    marginBottom: 20,
    minHeight: "50px",
    "& a": {
      textDecoration: 'none'
    },
    fontWeight: 400,
    color: '#4d5156'
  },
  minumumTitleSpacer: {
    minHeight: 75
  },
  icon: {
    fontSize: '1.3em',
    marginBottom: '-3px',
    marginRight: '5px'
  },
  iconSmall: {
    fontSize: '1.3em',
    marginBottom: '-5px',
    marginRight: '5px',
    color: "#4d5156",
  }, 
  iconLabel: { 
    color: "#4d5156",
    fontWeight: 400
  }, 
  card: {
    padding: 15
  },
  cardBody: {
    padding: 0
  },
  cardTitle: {
    fontWeight: 400
  },
  cardCategory: {
    marginTop: "10px",
    "& svg": {
      position: "relative",
      top: "8px"
    }
  },
  cardDescription: {
    ...description,
    fontSize: 14
  },
  cardDescriptionLink: {
    ...description,
    fontSize: 15,
    fontWeight: 400,
    "& a": {
      textDecoration: 'none'
    },
  },
  cardDescriptionHeader: {
    ...description,
    fontSize: ".75rem", 
    fontWeight: 500,
    textTransform: "uppercase"
  },
  marginBottom20: {
    marginBottom: "20px"
  },
  iconWrapper: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    margin: "10px auto 0",
    width: "110px",
    height: "110px",
    border: "1px solid " + grayColor[14],
    borderRadius: "50%",
    lineHeight: "155px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "55px",
      lineHeight: "55px"
    },
    "& svg": {
      width: "55px",
      height: "55px"
    }
  },
  smaller: {
    overflow: "hidden",
    marginTop: "5px",
    "& p": {
      color: '#4d5156',
      fontSize: "12px"
    },
    color: "#4d5156",
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: 0,
    letterSpacing: 'normal',
    textTransform: 'none'
  },
  iconWrapperColor: {
    borderColor: "rgba(" + hexToRgb(whiteColor) + ", 0.25)"
  },
  iconWhite: {
    color: whiteColor
  },
  iconRose: {
    color: roseColor[0]
  },
  iconInfo: {
    color: infoColor[0],
    fontSize: 55
  },
  cardAlignCentre: {
    textAlign: 'center'
  }
};

export default productStyle;
