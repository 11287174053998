import React from 'react';
import ContractPageComponent from 'components/views/ContractPage/ContractPage';
import { useDispatch } from 'react-redux';
import {
    fetchContract,
    //    clearContract, 
    //  selectContract
} from 'store/slices/contractPage/selectedContract';
import { setCurrentPage } from 'store/slices/routing/currentPage';

const ContractPageController = ({ match, location }) => {
    console.log("contract page controller update");
    const identifier = match.params.id;
    const dispatch = useDispatch();

    dispatch(setCurrentPage("contract"));



    React.useEffect(
        () => {
            //if(projectsStore[identifier] === null)
            //dispatch(selectProject(projectsStore[identifier]))
            //else
            dispatch(fetchContract(identifier));
        },
        [identifier, dispatch]
    )


    const Page = React.useMemo(
        () => <ContractPageComponent />,
        []
    );
    return Page;
}

export default ContractPageController;
