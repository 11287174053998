import React from "react";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components  
import ProjectCard from 'components/Project/ProjectCard';
import RelatedItemsCarousel from './RelatedItemsCarousel';

// const useStyles = makeStyles(theme => ({
// }));

const SectionCarouselResults = props => {
  const items = props.items || [];

  return (
    <RelatedItemsCarousel loading={props.loading} numberOfCards={props.numberOfCards}>
      {Object.values(items).map((searchResult, idx) => (
        <ProjectCard key={searchResult.ProjectId} project={searchResult} />
      ))}
    </RelatedItemsCarousel>
  );
}

export default SectionCarouselResults;

