import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"; 
// core components 
import ProjectSearchResult from "components/Project/ProjectSearchResult.js";
import { selector as searchResultsSelector } from 'store/slices/search/searchResults';
import { useSelector } from 'react-redux' 

const styles = theme => ({
  infoArea: {
    margin: "0 auto",
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  descriptionWrapper: {
    color: theme.palette.grey[400],
    overflow: "hidden"
  },
  selectedTitle: {
    margin: "1.0rem 0 0rem !important",
    minHeight: "unset",
    "& a": {
      textDecoration: 'none'
    },
    fontWeight: 400,
    color: '#4d5156'
  },
  title: {
    margin: "2rem 0 0.875rem !important",
    minHeight: "unset",
    "& a": {
      textDecoration: 'none'
    },
    fontWeight: 400,
    color: '#4d5156'
  },
  description: {
    overflow: "hidden",
    marginTop: "0px",
    marginBottom: 20,
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 400
  },
  descriptionStrong: {
    overflow: "hidden",
    marginTop: 15,
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    marginBottom: 5,
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 600
  },
  gridDescription: {
    overflow: "hidden",
    marginTop: 0,
    marginBottom: 5,
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 400
  },
  smaller: {
    overflow: "hidden",
    marginTop: "5px",
    "& p": {
      color: '#4d5156',
      fontSize: "12px"
    },
    color: "#4d5156",
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: 0,
    letterSpacing: 'normal',
    textTransform: 'none'
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  },
  icon: {
    fontSize: '1.3em',
    marginBottom: '-3px',
    marginRight: '5px'
  },
  iconSmall: {
    fontSize: '1.3em',
    marginBottom: '-5px',
    marginRight: '5px',
    color: "#4d5156",
  },
  section: {
    paddingTop: 0,
    minHeight: 500
  },
  link: {
    fontSize: "14px",
    textDecoration: "none",
    fontWeight: 400,
    marginRight: 12.5
  },
  main: {
    marginTop: 150
  },
  paper: {
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    backgroundColor: 'transparent',
    borderRadius: 8
  },
  paperResult: {
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },
  resultItem: {
    padding: 25
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  spacerBelow: {
    marginBottom: 25
  }
});

const useStyles = makeStyles(styles);

const SectionResults = props => {
  const classes = useStyles();
  const { results } = useSelector(searchResultsSelector); 

  return (
    <div className={classes.section} ref={props.scrollRef}>
      {Object.values(results)
        .map((result, index) => {
          return (index === 0 && result['@search.score'] && result['@search.score'] > 1.5) ?
            <ProjectSearchResult key={result.ProjectId} item={result} initiallyExpanded={true} />
            : <ProjectSearchResult key={result.ProjectId} item={result} />
        }
      )}
    </div>
  );
}

export default SectionResults;