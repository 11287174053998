import React from 'react';
import SearchPageComponent from 'components/views/SearchPage/SearchPage';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { getSearch } from 'store/slices/search/searchResults';
import { getSearchCarousel } from 'store/slices/search/searchCarousel';
import { setQueryText } from 'store/slices/search/searchResultsQuery';
import { setSearchText } from 'store/slices/search/searchText';
import { setSearchResultsPage } from 'store/slices/search/searchResultsPage';
import { setCurrentPage } from 'store/slices/routing/currentPage';

const SearchPageController = ({ history, location }) => {
    console.log("search page controller update");
    // console.log(history);

    const currentQuery = queryString.parse(history.location.search);
    const searchQuery = currentQuery.searchQuery;
    const page = currentQuery.page;
    const dispatch = useDispatch();

    dispatch(setCurrentPage("search"));

    React.useEffect(
        () => {
            dispatch(setSearchText(searchQuery));
            dispatch(setQueryText(searchQuery));
            dispatch(getSearch());
            dispatch(getSearchCarousel());

        },
        [searchQuery, dispatch]
    )

    React.useEffect(
        () => {
            dispatch(setSearchResultsPage(page || 1));
            dispatch(getSearch());
            dispatch(getSearchCarousel());
        },
        [page, dispatch]
    )


    const SearchPage = React.useMemo(
        () => <SearchPageComponent />,
        []
    );
    return SearchPage;
}

export default SearchPageController;
