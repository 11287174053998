import { createSlice } from '@reduxjs/toolkit';
import projectApi from './project-edit-service';
import { postMessage } from 'store/slices/messages/messages';
//import { push } from 'connected-react-router';
import { GetToken } from 'CustomAuthProvider';
import { editSelectedProject } from './selectedProject';

export const initialState = {
  hasErrors: false,
  loading: false,
  results: [],
  elapsedTime: null,
}

const sliceName = 'tagsEditing';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setTagsEditStarting: state => {
      state.hasErrors = false;
      state.loading = true;
      state.results = [];
    },
    setTagsEditSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
    },
    setTagsEditFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setTagsEditStarting, setTagsEditSuccess, setTagsEditFailure } = slice.actions
export const tagsEditingSelector = state => state[sliceName];
export default slice.reducer

export const editTags = (projectId, newTags) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setTagsEditStarting());

      const token = await GetToken();

      const results = await projectApi.editTags(token.idToken, projectId, newTags);
      
      dispatch(setTagsEditSuccess(results));
      dispatch(editSelectedProject({ Tags_current: newTags }))
    } catch (error) {
      dispatch(setTagsEditFailure());
      dispatch(postMessage({ type: "warning", text: "Tag editing error: " + error }));
    }
  }
}
