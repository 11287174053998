import { createTheme } from '@material-ui/core/styles';
//import purple from '@material-ui/core/colors/purple';
//import green from '@material-ui/core/colors/green';

const theme = createTheme({
    palette:{
        primary:{
            main:'#9c27b0'
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        body1: {//equivalent to p from the original theme
            lineHeight: "1.5em",
            fontWeight: "300",
            letterSpacing:'normal',
            fontColor:'red',
            fontSize:'14px',
            margin:'0 0 10px',
        },
        body2: {//cssbaseline sets this as the default body/div settings
            fontSize: '1rem',
            lineHeight: "1.5em",
            fontWeight: "300",
            letterSpacing:'normal'
        },
        h1: {
            fontSize: '3.3125rem',
            lineHeight: "1.15em",
            fontWeight: "300",
            marginTop: "20px",
            marginBottom: "20px",
            letterSpacing:'normal'
        },
        h2: {
            fontSize: '2.25rem',
            lineHeight: "1.5em",
            fontWeight: "300",
            marginTop: "20px",
            marginBottom: "20px",
            letterSpacing:'normal'
        },
        h3: {
            fontSize: '1.5625rem',
            lineHeight: "1.4em",
            fontWeight: "300",
            marginTop: "20px",
            marginBottom: "20px",
            letterSpacing:'normal'
        },
        h4: {
            fontSize: "1.125rem",
            lineHeight: "1.5em",
            fontWeight: "300",
            marginTop: "10px",
            marginBottom: "10px",
            letterSpacing:'normal'
        },
        h5: {
            fontSize: '1.0625rem',
            lineHeight: "1.55em",
            fontWeight: "300",
            marginTop: "10px",
            marginBottom: "10px",
            letterSpacing:'normal',
        },
        h6: {
            fontSize: '0.75rem',
            textTransform: 'uppercase',
            lineHeight: "1.5em",
            fontWeight: "500",
            marginTop: "10px",
            marginBottom: "10px",
            letterSpacing:'normal',
        },
         button: {
            fontSize: "12px",
            fontWeight: "400",
            textTransform: "uppercase",
         }
    },
    props:{
        MuiLink:{
            underline:'none'
        }
    },
});

export default theme;