/*eslint-disable*/
import React, { useState } from "react";
import Box from '@material-ui/core/Box';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// core components
import { Card as MatCard } from "@material-ui/core";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from "@material-ui/core/CardContent";
import Small from 'components/Typography/Small';
import Paper from "@material-ui/core/Paper";

import { useDispatch, useSelector } from 'react-redux'
import { selectPost, postSelector } from 'store/slices/posts/post';

const useStyles = makeStyles(theme => ({
  listpost: {
    margin: '10px 0px',
    borderRadius: '5px',
    border: '1px solid #7777771D',
    cursor: 'pointer',
  }
}));

const ListPost = props => {
  const post = props.post;
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const onPostSelection = () => {
    dispatch(selectPost(props.post.id))
  }
  console.log('render list item')
  return (
    <Paper className={classes.hoverSelect} elevation={1}>
      <MatCard
        className={classes.listpost + (props.selected ? " " + classes.selected : "")}
      >
        <CardActionArea onClick={onPostSelection}>
          <CardContent>
            <Typography variant='h5' gutterBottom>
              {post.title}
            </Typography>
            <Typography variant='body1'>
              {post.id}
            </Typography>
          </CardContent>
        </CardActionArea>
      </MatCard>
    </Paper>

  );
}

export default ListPost;