import React from 'react';
import {
  TimePicker,
} from '@material-ui/pickers';

//Note: This components expects a MuiPickersUtilsProvider higher up
// in the tree as per: https://material-ui-pickers.dev/getting-started/installation
const MaterialUIPickers = props => {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <TimePicker
      margin="normal"
      id="time-picker"
      label="Time picker"
      value={selectedDate}
      onChange={handleDateChange}
    />
  );
};
export default MaterialUIPickers;


