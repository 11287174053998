import React from 'react';
import Card from '@material-ui/core/Card';
import CardBody from "components/Card/CardBody.js";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Avatar, CardMedia } from '@material-ui/core';
import useIsActiveProject from 'hooks/useIsActiveProject';
import MuiBadge from 'components/Badge/MuiBadge';
import { MarketSegmentIcon } from 'components/Icons/MarketSegmentIcons';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    card: {
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardBody: {
        width:'100%',
        marginTop: theme.spacing(2),
        padding: 0,
    },
    cardMedia: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    cardCategory: {
        marginTop: "10px",
        "& svg": {
            position: "relative",
            top: "8px"
        }
    },
    cardDescription: {
        color: theme.palette.grey[500],
        fontSize: 14
    },
    iconSmall: {
        fontSize: '1.3em',
        marginBottom: '-4px',
        marginRight: '5px',
        color: "#4d5156",
    },
    iconLabel: {
        color: "#4d5156",
        fontWeight: 400,
        fontSize: '1.1em'
    },
    selectedTitle: {
        margin: "0rem 0 0 !important",
        "& a": {
            textDecoration: 'none'
        },
        fontWeight: 400,
        color: '#4d5156'
    },

    avatar: {
        color: theme.palette.common.white,
        backgroundColor: blue[500],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    projectIcon: {
        fontSize: '24px',
    },
}));

const ProjectCard = props => {
    const classes = useStyles(props);
    const project = props.project;

    const activeProject = useIsActiveProject(project.ProjectStartDate, project.ProjectFinishDate);

    return (
        <Card variant="outlined" className={classes.card}>
            <CardMedia className={classes.cardMedia}>
                <MuiBadge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent=" "
                    variant='dot'
                    color={activeProject ? "active" : "inactive"}
                    size={16}
                    borderWidth={2}
                >
                    <Avatar className={classes.avatar}>
                        <MarketSegmentIcon className={classes.projectIcon} marketSegment={project.ProjectMarketSegmentName} />
                    </Avatar>
                </MuiBadge>
            </CardMedia>
            <CardBody className={classes.cardBody}>
                <Typography variant="caption" display="block">
                    {project.ProjectCode}
                </Typography>

                <Typography className={classes.selectedTitle}>
                    <Link component={RouterLink} to={"/project/" + project.ProjectId}>
                        {project.ProjectName.length > 45 ? project.ProjectName.substring(0, 45) + "..." : project.ProjectName}
                    </Link>
                </Typography>

            </CardBody>
        </Card>
    );
}

export default ProjectCard;