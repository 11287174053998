import queryString from 'query-string';

export const pushPage = (history, pagePathName, queryParams, clearExistingParams) => {
    let queryObject = {};
    if(!clearExistingParams){
        queryObject = queryString.parse(history.location.search);
    }
    Object.entries(queryParams).forEach(([key, value]) => {
        queryObject[key] = value;
    });
    history.push({
        pathname: pagePathName,
        search: queryString.stringify(queryObject)
    });
}