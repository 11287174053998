import { createSlice } from '@reduxjs/toolkit';
import editApi from 'store/slices/projectPage/project-edit-service';
import { postMessage } from 'store/slices/messages/messages';
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  edits: null,
}

const sliceName = 'selectedProjectEdits';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setStarting: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.edits = payload;
    },
    setFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setStarting, setSuccess, setFailure, } = slice.actions
export const selectedProjectEditsSelector = state => state[sliceName];
export default slice.reducer

export const getProjectEdits = (projectId) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setStarting());

      const tokenPromise = GetToken();
      const token = await tokenPromise;

      const results = await editApi.getEdits(token, projectId);

      //const parsedResults = parseSearchResults(results);

      //console.log(parsedResults)
      dispatch(setSuccess(results));

     
    } catch (error) {
      dispatch(setFailure());
      postMessage({ type: "warning", text: "Fetching Project Edits Error: " + error });
    }
  }
}


