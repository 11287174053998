import { createSlice } from '@reduxjs/toolkit'  

export const initialState = {
  hasErrors: false,
  loading: false,   
  identifier: null
}

const sliceName = 'searchSelected';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSelectedInternal: (state, { payload }) => {
      const { identifier } = payload;
      
      state.loading = false;
      state.hasErrors = false; 
      state.identifier = identifier; 
    },
    setSelectedFailure: (state, { payload }) => {
      state.hasErrors = true;  
    },
  },
})

export const { setSelectedInternal, setSelectedFailure, } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer
 
export const setSelected = (identifier) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.       
      dispatch(setSelectedInternal({ 
        identifier: identifier
      }));   
    } catch (error) {
        console.log(error)
        dispatch(setSelectedFailure());        
        postMessage({ type: "warning", text: "Selecting Search Error: " + error });
    }
  }
}
 
   
   