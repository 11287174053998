/*eslint-disable*/
import React, { useRef, useEffect } from "react";
// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {
  List,
  ListItem,
  Typography,
} from "@material-ui/core";

// core components
import Header from "components/Header/SearchHeader.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/WelcomeHeaderLinks.js";
import landingPageStyle from "./searchPageStyle.js";
// Sections for this page
import SectionSummaryResults from "./Sections/SectionSummaryResults.jsx";
import SectionResults from "./Sections/SectionResults";
import SectionCarouselResults from "./Sections/SectionCarouselResults";
import Button from "components/CustomButtons/Button.js";
import SectionMoreLikeThisResults from "./Sections/SectionMoreLikeThisResults.js"
import PaginationResults from "./Sections/PaginationResults.js"
import HeaderLinksResponsive from "components/Header/WelcomeHeaderLinksResponsive.js";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(landingPageStyle);

function TopSpacer() {
  return <div style={{ height: "20px" }}></div>
}

export default function SearchPage({ ...rest }) {
  const classes = useStyles();
  const scrollRef = useRef(null);
  console.log('render search page')
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  return (
    <div>
      <Header
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
        linksResponsive={<HeaderLinksResponsive />}
        fixed
        gutterBottom
        {...rest}
      />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          {/* <TopSpacer /> */}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <SectionSummaryResults />
            </Grid>
          </Grid>

          {/* <Grid container spacing={0}>
            <Grid item xs={11}>

            </Grid>
          </Grid> */}

          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
              <SectionResults scrollRef={scrollRef} />s
            </Grid>
            <Grid item md={1} lg={1} xl={1}>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={4}>
              <SectionCarouselResults />
              <SectionMoreLikeThisResults />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
              <PaginationResults />
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <Link
                href="https://www.dialogdesign.ca"
                target="_blank"
                className={classes.footerBrand}
              >
                DIALOG
              </Link>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://www.dialogdesign.ca/how-we-dialog/"
                    target="_blank"
                    className={classes.block}
                  >
                    How
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://www.dialogdesign.ca/our-work/"
                    target="_blank"
                    className={classes.block}
                  >
                    Work
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://www.dialogdesign.ca/our-team/"
                    className={classes.block}
                  >
                    Team
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="#"
                    target="_blank"
                    className={classes.block}
                  >
                    DevHub
                  </Link>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="https://twitter.com/dialog"
                    target="_blank"
                    color="twitter"
                    justIcon
                    simple
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://www.instagram.com/dialogdesign/"
                    target="_blank"
                    color="instagram"
                    justIcon
                    simple
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://www.youtube.com/user/StartDIALOG"
                    target="_blank"
                    color="youtube"
                    justIcon
                    simple
                  >
                    <i className="fab fa-youtube" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}
