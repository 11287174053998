import { useDispatch } from "react-redux";
import React, { useEffect, } from 'react';
import { loginRequest } from "authConfig";
import { startLoginToAuth } from "store/slices/auth/auth";
import { loginToAuthSuccess } from "store/slices/auth/auth";
import { loginToAppThunk } from "store/slices/auth/auth";
//msal imports
import { useMsal } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./CustomNavigationClient";
import { useHistory } from "react-router";


export const msalInstance = new PublicClientApplication(msalConfig);


// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});


export async function GetToken() {
    let token = null;
    try {
        token = await msalInstance.acquireTokenSilent(loginRequest);
    } catch (err) {
        console.log('silent token acquisition failure, trying redirect')
        token = await msalInstance.acquireTokenRedirect(loginRequest);
    }
    if (!token.idToken) {
        throw new Error('No token returned');
    }
    return { idToken: { rawIdToken: token.idToken } };
}

/**
 * Connects the msal-react library to our Redux store and handles initial login attemps.
 */
export function CustomAuthProvider({ children }) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    msalInstance.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={msalInstance}>
            <CustomAuthConnector />
            {children}
        </MsalProvider>
    )
}

function CustomAuthConnector() {
    useInitializeMsal();
    return null;
}
function useInitializeMsal() {
    const { instance, accounts, inProgress } = useMsal();
    const dispatch = useDispatch();
    useEffect(() => {
        if (inProgress === "none" && accounts.length > 0) {
            // Retrieve an id token
            const loginFunction = async () => {
                dispatch(startLoginToAuth())
                try {
                    const silentResponse = await instance.acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0],
                        forceRefresh: true,
                    });
                    if (silentResponse?.idToken) {
                        const payload = { idToken: silentResponse.idToken };
                        if (silentResponse?.idTokenClaims?.exp) {
                            payload.expiresOn = processExpClaim(silentResponse.idTokenClaims.exp)?.toISOString();
                        }
                        dispatch(loginToAuthSuccess(payload));
                        dispatch(loginToAppThunk());
                        return;
                    }
                } catch (err) {
                    console.log('silent token acquisition failure, trying redirect')
                    await instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: accounts[0],
                    });
                }
            }
            loginFunction();
        }
    }, [inProgress, accounts, instance, dispatch]);

    return [inProgress,]
}

/**
 * Converts an idToken's standard "exp" claim to a Date object. Defined as number of seconds since unix epoch.
 * @param {*} expClaim The number of seconds after unix epoch that the id token will expire.
            * @returns //a Date object representing the expiry time of the id token.
            */
function processExpClaim(expClaim) {
    const unixEpoch = new Date('1970-jan-01');
    const result = new Date(unixEpoch);
    result.setSeconds(result.getSeconds() + expClaim);
    return result;
}