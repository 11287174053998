import React from "react"; 
// @material-ui/core components
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import contactStyle from "./sectionUsersStyle.js"; 

const useStyles = makeStyles(contactStyle);

const RoleSelector = props => {

  const handleSelectionChange = event => {
    const item = event.target.value;
    if (props.onItemSelected) {
      props.onItemSelected(item);
    }
  };

  const classes = useStyles();

  return (
    <Select
      MenuProps={{
        className: classes.selectMenu
      }}
      classes={{
        select: classes.select
      }}
      value={props.selectedItem}
      onChange={handleSelectionChange}
      inputProps={{
        name: "selectedItem",
        id: "selected-item"
      }}
    >
      {props.header &&
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
        >
          {props.header}
        </MenuItem>
      }
      {(props.items && props.items.length > 0) &&
        props.items.map(item => (
          <MenuItem
            key={item.key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={item.item}
          >
            {item.display}
          </MenuItem>
        ))}
    </Select>
  );
}

export default RoleSelector;