import React from 'react';
import { FormControlLabel, Switch,  } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selector as activeOnlySelector } from 'store/slices/search/searchActiveOnly';
import { switchActiveOnly } from 'store/slices/search/searchActiveOnly';
import { getSearch } from 'store/slices/search/searchResults';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    switch_track: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[800],
    },
    switch_base: {
        color: theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[800],
        "&.Mui-disabled": {
            color: theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[800]
        },
        "&.Mui-checked": {
            color: theme.palette.success.light
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.success.main,
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: theme.palette.success.main,
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.success.main,
        },
    },
    label: {
        marginBottom: 0,
    }

}));

export function ActiveOnlyToggle({ labelPlacement }) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const { searchActiveOnly } = useSelector(activeOnlySelector);
    return (
        <FormControlLabel
            classes={{ label: classes.label }}
            labelPlacement={labelPlacement || "bottom"}
            control={(
                <Switch
                    size="small"
                    classes={{
                        track: classes.switch_track,
                        switchBase: classes.switch_base,
                        colorPrimary: classes.switch_primary,
                    }}
                    value="check"
                    checked={searchActiveOnly}
                    onChange={() => {
                        dispatch(switchActiveOnly())
                        dispatch(getSearch())
                    }}
                    style={{
                        alignSelf: 'center',
                    }}
                    color="secondary"
                >
                    Label
                </Switch>
            )}
            label={searchActiveOnly ? "Active" : "All"}
        />

    );
}