/*eslint-disable*/ import React, { useRef, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {
  List,
  ListItem,
  Typography
} from "@material-ui/core";
// @material-ui/icons
import {
  Favorite,
} from "@material-ui/icons";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/WelcomeHeaderLinks.js";
import HeaderLinksResponsive from "components/Header/WelcomeHeaderLinksResponsive.js";
import Parallax from "components/Parallax/Parallax.js";
import SearchBar from './WelcomeSearchBar';
// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";
import SectionTeam from "./Sections/SectionTeam.js";
import SectionWork from "./Sections/SectionWork.js";
//styling
import {
  container,
  title,
  main,
  whiteColor,
  mainRaised
} from "components/themeStyle.js";

const useStyles = makeStyles(theme=>({
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: whiteColor,
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  }
}));

export default function LandingPage({ ...rest }) {
  const classes = useStyles();
  const scrollRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const executeScroll = () => {
    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };


  return (
    <div>
      <Header
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="info" />}
        linksResponsive={<HeaderLinksResponsive />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
        {...rest}
      />
      <Parallax image={require("../../../img/D_200110_N42.jpg")} >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}> 
              <SearchBar />
              <br />
              <Button
                color="danger"
                size="lg"
                onClick={executeScroll}
              >
                Learn More
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct scrollRef={scrollRef} />
          <SectionTeam />
          <SectionWork />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://www.dialogdesign.ca/"
                    target="_blank"
                    className={classes.block}
                  >
                    DIALOG
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://dash.dialogdesign.ca/content/18674"
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="https://dash.dialogdesign.ca/content/18675"
                    target="_blank"
                    className={classes.block}>
                    Blog
                  </Link>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> by DIALOG's {" "}
              <Link
                href="https://dash.dialogdesign.ca/content/18674"
                target="_blank"
              >
                DevHub
              </Link>{" "}
              .
            </div>
          </div>
        }
      />
    </div>
  );
}
