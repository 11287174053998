import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SearchBar from './SearchPageSearchBar';
import Logo from './Logo';

import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Drawer,
  Grid,
} from "@material-ui/core";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
// core components
import styles from "./searchHeaderStyle.js";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FacetsSection } from "./FacetsSection/FacetsSection";
import { FilterToggle } from "./FacetsSection/FilterToggle";
import { useSelector } from "react-redux";
import { displayFiltersOnPageSelector } from "store/slices/routing/currentPage";
import { ActiveOnlyToggle } from "./FacetsSection/ActiveOnlyToggle";
import { ClearFilters } from "./FacetsSection/FilterToggle";

const useStyles = makeStyles(styles);



export default function SearchHeader(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up('sm'));

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, links, linksResponsive, fixed, absolute, gutterBottom } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.bottomSpacer]: !gutterBottom,
  });

  const [filtersOpen, setFiltersOpen] = useState(false);

  const displayFiltersOnPage = useSelector(displayFiltersOnPageSelector);//this header is being reused for the project and contract display pages, so this will be false for them.

  return (
    <>
      <AppBar className={appBarClasses}>
        <Grid direction="column" container>
          <Grid item xs={12}>
            <Toolbar className={classes.container}>
              <Logo className={classes.logo} />

              <div className={notMobile ? classes.searchBar : classes.searchBarSmall} >
                <SearchBar />
              </div>
              {notMobile && displayFiltersOnPage &&
                <ActiveOnlyToggle />
              }
              {notMobile && displayFiltersOnPage &&
                <FilterToggle selected={filtersOpen} onChange={() => setFiltersOpen(!filtersOpen)} />
              }
              {notMobile && displayFiltersOnPage &&
                <ClearFilters  />
              }

              <Hidden smDown implementation="css" className={classes.hidden}>
                <div className={classes.collapse}>{links}</div>
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <Menu />
                </IconButton>
              </Hidden>
              <Hidden mdUp implementation="js">
                <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={mobileOpen}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  onClose={handleDrawerToggle}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    className={classes.closeButtonDrawer}
                  >
                    <Close />
                  </IconButton>
                  <div className={classes.appResponsive}>{linksResponsive}</div>
                </Drawer>
              </Hidden>

            </Toolbar>
          </Grid>
          {filtersOpen && displayFiltersOnPage &&
            <Grid item>
              <div className={classes.container}>
                <FacetsSection />
              </div>
            </Grid>
          }
        </Grid>
      </AppBar>
    </>
  );
}

SearchHeader.defaultProp = {
  color: "white"
};

SearchHeader.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
