
import customReducers from './slices';
import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

const createRootReducer = history => {
    return combineReducers({
        ...customReducers,
        //router: connectRouter(history),
    })
}

const createStore = history => {
    const middleware = getDefaultMiddleware();
    //middleware.push(routerMiddleware(history));
    const store = configureStore({
        reducer: createRootReducer(history),
        middleware: middleware,
    });
    return store;
}
export default createStore;