import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  searchActiveOnly: true
}
const sliceName = 'searchActiveOnly';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    switchActiveOnly: (state,) => {
      state.searchActiveOnly = !state.searchActiveOnly;
    },
    switchActiveOnlyOff: (state,) => {
      if (state.searchActiveOnly) {
        state.searchActiveOnly = false;
      }
    },
  },
})

export const { switchActiveOnly,switchActiveOnlyOff } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer
