import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Typography from '@material-ui/core/Typography';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import productStyle from "./productStyle.js";

const useStyles = makeStyles(productStyle);
const SectionProduct = props => {
  const classes = useStyles();
  if(!props.scrollRef){
    console.log('no Ref!')
  }
  return (
    <div className={classes.section} ref={props.scrollRef} >
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <Typography variant="h2" className={classes.title}>Project Explorer</Typography>
          <Typography variant="h5" className={classes.description}>
            DIALOG's Project Explorer is here to let you search and interact with your project data quickly and efficiently, no matter where you are.
            Currently it allows you to locate data from <b>300,000</b> project tasks and <b>200,000</b> resources sourced directly from BST.
          </Typography>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="300,000"
              description="300,000+ tasks, searchable in full text from across DIALOG's projects."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="200,000"
              description="200,000+ resources, available at the tip of your finger, so you know exactly who worked on what."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="1"
              description="Just 1 search bar, so you always know where to start."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default SectionProduct;