/*eslint-disable*/
import React, { useEffect } from "react";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import CodeIcon from "@material-ui/icons/Code";
import GroupIcon from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Grid from '@material-ui/core/Grid';
//import Grid container from "components/Grid/Grid container.js";
//import Grid item from "components/Grid/Grid item.js";
import Button from "components/CustomButtons/Button.js";
import { Card as MatCard } from "@material-ui/core";
import Card from "components/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import Small from 'components/Typography/Small';
import CardBody from "components/Card/CardBody";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slider from './StateSlider';

import statePageStyle from "./statePageStyle.js";

import image from "img/bg7.jpg";

//state stuff
import { useDispatch, useSelector } from 'react-redux'
import { fetchPosts, postsSelector } from 'store/slices/posts/posts';
import SelectedPost from './SelectedPost';
import PostsList from './PostsListPane';

const useStyles = makeStyles(statePageStyle);

const StatePage = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  //state stuff

  const dispatch = useDispatch();
  const { posts, loading, hasErrors, } = useSelector(postsSelector)

  useEffect(() => {
    dispatch(fetchPosts())
  }, [dispatch])

  console.log('rendering state page');
  console.log(props);
  console.log(posts);
  console.log(loading);
  console.log(hasErrors);

  const loadPosts = () => {
    dispatch(fetchPosts())
  };


  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="rose" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container} >
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <Typography variant='h2' className={classes.cardTitle}>State Management Example</Typography>
                <Slider />
                <CardBody>
                  <Grid container justify="center" spacing={4}>
                    <Grid item xs={12} sm={5} md={5}>
                      <Grid className={classes.postsHeader} container justify="space-between" alignItems="center" >
                        <Grid item container xs={1} alignItems="center">
                          <Timeline />
                        </Grid>
                        <Grid item container xs justify="flex-start" alignItems="center">
                          <Typography variant="h4" className={classes.poststitle} >
                            Posts
                          </Typography>
                        </Grid>
                        <Grid item container xs justify="flex-start" direction="row-reverse" alignItems="center">
                          {((!posts || posts.length === 0) && !loading) && (
                            <Button className={classes.postsButton} onClick={loadPosts} >
                              <Typography className={classes.narrowButton} variant='button' >
                                Load Posts
                                </Typography>
                            </Button>
                          )}
                          {loading && (
                            <Button className={classes.postsButton} >
                              <Typography className={classes.narrowButton} variant='button' >
                                Loading...
                                </Typography>
                            </Button>
                          )}
                          {(posts && posts.length > 0 && !loading) &&
                            <Button className={classes.postsButton} onClick={loadPosts} >
                              <Typography className={classes.narrowButton} variant='button' >
                                Refresh
                                </Typography>
                            </Button>
                          }
                        </Grid>
                      </Grid>
                      <PostsList />

                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                      <SelectedPost />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/?ref=mkpr-signup"
                      target="_blank"
                      className={classes.block}
                    >
                      Creative Tim
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/presentation?ref=mkpr-signup"
                      target="_blank"
                      className={classes.block}
                    >
                      About us
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="//blog.creative-tim.com/"
                      className={classes.block}
                    >
                      Blog
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/license?ref=mkpr-signup"
                      target="_blank"
                      className={classes.block}
                    >
                      Licenses
                    </Link>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <Link
                  href="https://www.creative-tim.com?ref=mkpr-signup"
                  target="_blank"
                >
                  Creative Tim
                </Link>{" "}
                for a better web.
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
export default StatePage;
