import React from 'react';

import { FaLandmark, FaRegHospital, FaIndustry, FaTheaterMasks, } from 'react-icons/fa';
import { MdOutlineStore, MdOutlineSchool, MdOutlineLocationCity, MdOutlineLocalAirport, MdOutlineTrain, MdOutlineScience, MdOutlineStorefront, MdOutlineHotel } from 'react-icons/md';
import { GiModernCity, GiPresent, GiSolarPower, GiShinyApple, GiSoccerField, GiSuspensionBridge } from 'react-icons/gi';
import { HiOutlineOfficeBuilding, } from 'react-icons/hi';
import { BsLayoutWtf, } from 'react-icons/bs';
import { RiGovernmentFill, } from 'react-icons/ri';
import { BiBuildingHouse, } from 'react-icons/bi';
import { CgTrees, } from 'react-icons/cg';
import { IoStorefront, IoHelpSharp} from 'react-icons/io5';



export const ProjectIcon = FaLandmark;
export const RetailIcon = MdOutlineStore;
export const HealthCareIcon = FaRegHospital;
export const PostSecondaryIcon = MdOutlineSchool;
export const OfficeBuildingsIcon = GiModernCity;
export const OfficeTenanciesIcon = HiOutlineOfficeBuilding;
export const PlanningIcon = BsLayoutWtf;
export const GovernmentIcon = RiGovernmentFill;
export const MixedUseDevelopmentIcon = MdOutlineLocationCity;
export const OtherIcon = GiPresent;
export const EnergyIcon = GiSolarPower;
export const ResidentialIcon = BiBuildingHouse;
export const AirportIcon = MdOutlineLocalAirport;
export const IndustrialIcon = FaIndustry;
export const SchoolsIcon = GiShinyApple;
export const ArtsAndCultureIcon = FaTheaterMasks;
export const SportsAndArenasIcon = GiSoccerField;
export const HospitalityIcon = MdOutlineHotel;
export const BridgeWorkIcon = GiSuspensionBridge;
export const LRTTransitIcon = MdOutlineTrain;
export const ParksIcon = CgTrees;
export const RnDIcon = MdOutlineScience;
export const LightIndustrialIcon = FaIndustry;
export const RetailFitOutIcon = MdOutlineStorefront;
export const RetailBaseBuildingIcon = IoStorefront;


export const IconMap = {
    "Retail": RetailIcon,
    "Healthcare": HealthCareIcon,
    "Post Secondary": PostSecondaryIcon,
    "Office Buildings": OfficeBuildingsIcon,
    "Office Tenancies": OfficeTenanciesIcon,
    "Planning": PlanningIcon,
    "Government": GovernmentIcon,
    "Mixed Use Development": MixedUseDevelopmentIcon,
    "Other": OtherIcon,
    "Energy": EnergyIcon,
    "Residential": ResidentialIcon,
    "Airport": AirportIcon,
    "Industrial": IndustrialIcon,
    "Schools": SchoolsIcon,
    "Arts and Culture": ArtsAndCultureIcon,
    "Sports/Arenas": SportsAndArenasIcon,
    "Hospitality": HospitalityIcon,
    "Bridge Work": BridgeWorkIcon,
    "LRT/Transit": LRTTransitIcon,
    "Parks": ParksIcon,
    "R&D": RnDIcon,
    "Light Industrial": LightIndustrialIcon,
    "Retail Fit-out": RetailFitOutIcon,
    "Retail Base Building": RetailBaseBuildingIcon,
}

export function MarketSegmentIcon({ marketSegment, children, ...rest }) {
    const Icon = IconMap[marketSegment] || IoHelpSharp;
    
    return (
        <Icon {...rest} >
            {children}
        </Icon>
    );
}
