/*eslint-disable*/ 
import React from "react";
// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {
  List,
  ListItem,
} from "@material-ui/core";
// core components
import Header from "components/Header/SearchHeader.js";
import Footer from "components/Footer/Footer.js"; 
import HeaderLinks from "components/Header/WelcomeHeaderLinks.js"; 
import landingPageStyle from "./projectPageStyle.js";
// Sections for this page 
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.js";
import { selector as selectedProjectSelector } from 'store/slices/projectPage/selectedProject'; 
import { useSelector } from 'react-redux' 
import ProjectMain from "components/Project/ProjectMain.js";
import HeaderLinksResponsive from "components/Header/WelcomeHeaderLinksResponsive.js";

const useStyles = makeStyles(landingPageStyle);

export default function ProjectPage(props) {
  const classes = useStyles(); 
  const { selectedProject } = useSelector(selectedProjectSelector);
  
  
  return (
    <div>
      <Header
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
        linksResponsive={<HeaderLinksResponsive />}
        fixed
        {...props}
      />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <Grid container spacing={0}> 
           <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
              {selectedProject &&
                <>
                  <ProjectMain project={selectedProject} />  
                </>
              }
            </Grid>  
          </Grid>     
        </div>
      </div>
      <Footer 
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <Link
                  href="https://www.dialogdesign.ca"
                  target="_blank"
                  className={classes.footerBrand}
                >
                  DIALOG
                </Link>
              </div>
              <div className={classes.pullCenter}>
                <List className={classes.list}>                 
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.dialogdesign.ca/how-we-dialog/"
                      target="_blank"
                      className={classes.block}
                    >
                      How
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.dialogdesign.ca/our-work/"
                      target="_blank"
                      className={classes.block}
                    >
                      Work
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.dialogdesign.ca/our-team/"
                      className={classes.block}
                    >
                      Team
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="#"
                      target="_blank"
                      className={classes.block}
                    >
                      DevHub
                    </Link>
                  </ListItem>
                </List>
              </div>
              <div className={classes.rightLinks}>
                <ul>
                  <li>
                    <Button
                      href="https://twitter.com/dialog"
                      target="_blank"
                      color="twitter"
                      justIcon
                      simple
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="https://www.instagram.com/dialogdesign/"
                      target="_blank"
                      color="instagram"
                      justIcon
                      simple
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="https://www.youtube.com/user/StartDIALOG"
                      target="_blank"
                      color="youtube"
                      justIcon
                      simple
                    >
                      <i className="fab fa-youtube" />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          }
        />
    </div>
  );
}
