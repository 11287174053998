import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  errors: [],
  hasErrors: false,
  searchQuery: ""
}

const sliceName = 'searchQuery';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSearchQueryInternal: (state, { payload }) => {
      state.searchQuery = payload
      state.hasErrors = false
    },
    setSearchQueryFailure: (state, { payload }) => {
      state.hasErrors = true;
      state.errors.push(payload);
    },
  }
})

export const { setSearchQueryInternal, setSearchQueryFailure, } = slice.actions
export const searchSelector = state => state[sliceName];
export default slice.reducer

//also triggers suggestions fetching
export const setQueryText = (searchQuery) => {
  return async dispatch => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setSearchQueryInternal(searchQuery)) 
    } catch (error) {
      dispatch(setSearchQueryFailure(error + ""))
    }
  }
}