import React from 'react';
import {
  DatePicker,
} from '@material-ui/pickers';

//Note: This components expects a MuiPickersUtilsProvider higher up
// in the tree as per: https://material-ui-pickers.dev/getting-started/installation
const MaterialUIPickers  = props =>{
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  
    return (
        <DatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          id="date-picker-inline"
          label="Date picker inline"
          value={selectedDate}
          onChange={handleDateChange}
        />
    );
  };
export default MaterialUIPickers;


