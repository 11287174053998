import { createSlice } from '@reduxjs/toolkit' 
import { GetToken } from 'CustomAuthProvider';
import searchApi from './search-service';

export const initialState = {
  hasErrors: false,
  loading: false,
  moreLikeThis: [] 
}

const sliceName = 'searchMoreLikeThis';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setMoreLikeThisStarting: state => {
      state.hasErrors = false;
      state.loading = true;
      state.moreLikeThis = [];
    },
    setMoreLikeThisSuccess: (state, { payload }) => {
      const { elapsedTime, count, moreLikeThis } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.moreLikeThis = moreLikeThis; 
      state.count = count; 
      state.elapsedTime = elapsedTime;
    },
    setMoreLikeThisFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setMoreLikeThisStarting, setMoreLikeThisSuccess, setMoreLikeThisFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer
  
export const setMoreLikeThis = (identifier) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setMoreLikeThisStarting()); 
 
      const tokenPromise = GetToken();       
      const token = await tokenPromise;    
               
      const results = await searchApi.getMoreLikeThis( 
        'projects-index',
        identifier,
        "ProjectName,ProjectClientName,ContractName",
        "",
        "",
        20,
        token.idToken 
      );
         
      const parsedResults = parseSearchResults(results);
      
      dispatch(setMoreLikeThisSuccess({ moreLikeThis: parsedResults.results }));   
    } catch (error) {
        console.log(error)
        dispatch(setMoreLikeThisFailure());        
        postMessage({ type: "warning", text: "Like This Error: " + error });
    }
  }
}

const parseSearchResults = (results) => {
   
  const parsed = {}; 
  const resDict = {};  

  results.value.forEach(result => {           
    //sr.ProjectCode = sr.ProjectCode.replace('PC-', '')
    resDict[result.ProjectName] = { ProjectId: result.ProjectId, ProjectName: result.ProjectName } 
  });
  
  parsed.results = resDict;  
  
  return parsed;
}

export const clearMoreLikeThis = () => {
  return async (dispatch, getState) => {
    try {      
      dispatch(setMoreLikeThisSuccess({  
        moreLikeThis: [] 
      }));   
    } catch (error) {
        console.log(error)
        dispatch(setMoreLikeThisFailure());        
        postMessage({ type: "warning", text: "Clear Like This Error: " + error });
    }
  }
}
 
   
   