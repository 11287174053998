import { createSlice } from '@reduxjs/toolkit';
import searchApi from 'store/slices/search/search-service';
import { postMessage } from 'store/slices/messages/messages';
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  childProjects: [],
}

const sliceName = 'childProjects';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFetchingStarted: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setChildProjects: (state, { payload }) => {
      const { childProjects } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.childProjects = childProjects;
    },
    setFetchingFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setFetchingStarted, setChildProjects, setFetchingFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer


export const fetchChildProjects = (referenceContract) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setFetchingStarted());
      if (!referenceContract) { throw Error("Can't fetch related contracts from blank reference."); }
      const token = await GetToken();

      let queryString = "";
      // if (referenceContract.Id) {
      //   queryString = "Contract:(\"" + referenceContract.Id + "\")";
      // } else
       if (referenceContract.Code && referenceContract.Code !== 'CC-') {
        queryString = "ContractCode:(\"" + referenceContract.Code + "\")";
      } else if (referenceContract.Name) {
        queryString = "ContractName:(\"" + referenceContract.Name + "\")";
      } else {
        throw Error("Can't fetch child projects without code.");
      }
      queryString = encodeURIComponent(queryString);

      const results = await searchApi.getSearch(
        queryString,
        'projects-index',
        "",
        "",
        token.idToken,
        500,
        0,
        "",
        "",
      );

      const parsedResult = parseSearchResults(results);
      if (!parsedResult) {
        throw Error("Couldn't retrieve related projects");
      }
      dispatch(setChildProjects(parsedResult));
    } catch (error) {
      dispatch(setFetchingFailure());
      dispatch(postMessage({ type: "warning", text: "Child Projects Error: " + error }));
    }
  }
}

export const clearChildProjects = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setChildProjects([]));
    } catch (error) {
      dispatch(postMessage({ type: "warning", text: "Clearing child projects error: " + error }));
    }
  }
}

//this is to parse out the somewhat ugly search results, this should probably be moved server sode
const parseSearchResults = results => {
  const parsed = {};
  if (results.primary && results.primary.length > 0) {
    const res = results.primary[0];
    if (res.properties && res.properties.elapsedTime) {
      parsed.elapsedTime = res.properties.elapsedTime;
    } else { console.log("couldn't gather elapsed time") }
    if (res.result) {
      const resDict = {};//this turns the results into a dict for fast lookup.
      //it takes advantage of the fact that modern javascript preserves property order.
      //If you log the object with chrome dev tools it will display alphabetically and
      //look out of order, however, if you call Object.Values(), you'll see the original
      //expected order.
      if (res.result.value && res.result.value.length > 0) {
        res.result.value.forEach(sr => {
          resDict[sr.ProjectId] = sr
        });
      } else { console.log("couldn't gather results into dictionary") }
      parsed.childProjects = resDict;
    } else { console.log("couldn't gather result") }
  }

  return parsed;
}