import { createSlice } from '@reduxjs/toolkit';
import searchApi from 'store/slices/search/search-service';
import { postMessage } from 'store/slices/messages/messages';
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  relatedContracts: [],
}

const sliceName = 'relatedContracts';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setContractFetchingStarting: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setRelatedContracts: (state, { payload }) => {
      const { relatedContracts } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.relatedContracts = relatedContracts;
    },
    setContractFetchingFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setContractFetchingStarting, setRelatedContracts, setContractFetchingFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer


export const fetchRelatedContracts = (referenceContract) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setContractFetchingStarting());
      if (!referenceContract) { throw Error("Can't fetch related contracts from blank reference."); }
      const token = await GetToken();
 
      let queryString = "";
      if (referenceContract.MarketSegment) {
        queryString = "MarketSegment:(" + referenceContract.MarketSegment + ")";
      } else if (referenceContract.Name) {
        queryString = referenceContract.Name;
      } else {
        queryString = "*";
      }
      queryString = encodeURIComponent(queryString);
     
      let filter = "Scale ge " + (referenceContract.Scale > 3 ? (referenceContract.Scale - 3).toString() : "0") 
                    + " and Scale le " + (referenceContract.Scale + 3).toString()
                      + " and Id ne '" + referenceContract.Id + "' ";
      
      const results = await searchApi.getSearch(
        queryString,
        'contracts-index',
        "",
        filter,
        token.idToken,
        10,
        0,
        "",
        "",
      );
    
      const parsedResult = parseSearchResults(results);

      console.log(parsedResult)
      if (!parsedResult) {
        throw Error("Couldn't retrieve related projects");
      }
      dispatch(setRelatedContracts(parsedResult));
    } catch (error) {
      dispatch(setContractFetchingFailure());
      dispatch(postMessage({ type: "warning", text: "Related Contracts Error: " + error }));
    }
  }
}

export const clearRelatedContracts = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setRelatedContracts([]));
    } catch (error) {
      dispatch(postMessage({ type: "warning", text: "Related Contracts Error: " + error }));
    }
  }
}

//this is to parse out the somewhat ugly search results, this should probably be moved server sode
const parseSearchResults = results => {
  const parsed = {};
  if (results.primary && results.primary.length > 0) {
    const res = results.primary[0];
    if (res.properties && res.properties.elapsedTime) {
      parsed.elapsedTime = res.properties.elapsedTime;
    } else { console.log("couldn't gather elapsed time") }
    if (res.result) {
      const resDict = {};//this turns the results into a dict for fast lookup.
      //it takes advantage of the fact that modern javascript preserves property order.
      //If you log the object with chrome dev tools it will display alphabetically and
      //look out of order, however, if you call Object.Values(), you'll see the original
      //expected order.
      if (res.result.value && res.result.value.length > 0) {
        res.result.value.forEach(sr => {
          resDict[sr.Id] = sr
        });
      } else { console.log("couldn't gather results into dictionary") }
      parsed.relatedContracts = resDict;
    } else { console.log("couldn't gather result") }
  }

  return parsed;
}