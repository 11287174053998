import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, } from "react-router";
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

//state imports
import { Provider } from 'react-redux';
import createStore from './store/store';
//Providers
import { ThemeProvider } from '@material-ui/styles';
import theme from 'components/themes/customTheme.js';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pages for this product
import AboutUsPage from "components/views/AboutUsPage/AboutUsPage.js";
import BlogPostPage from "components/views/BlogPostPage/BlogPostPage.js";
import BlogPostsPage from "components/views/BlogPostsPage/BlogPostsPage.js";
import ComponentsPage from "components/views/ComponentsPage/ComponentsPage.js";
import ContactUsPage from "components/views/ContactUsPage/ContactUsPage.js";
import LandingPage from "components/views/LandingPage/LandingPage";
import WelcomePage from "components/views/WelcomePage/WelcomePage";
import LoginPage from "components/views/LoginPage/LoginPage.js";
import ProfilePage from "components/views/ProfilePage/ProfilePage.js";
import ProductPage from "components/views/ProductPage/ProductPage.js";
import SignupPage from "components/views/SignupPage/SignupPage.js";
import StatePage from "components/views/StatePage/StatePage.js";
import UsersPage from "components/views/UsersPage/UsersPage.js";
import ErrorPage from "components/views/ErrorPage";
import CallbackPage from "components/views/CallbackPage";
import SearchPage from 'pages/Search';
import ProjectPage from 'pages/Project';
import ContractPage from 'pages/Contract';
import MessageSnackbar from 'components/Snackbars/MessageSnackbar';

import { CustomAuthProvider } from './CustomAuthProvider';

const store = createStore();

const App = props => {
  return (
    <BrowserRouter >
      <CustomAuthProvider>
        <Switch>
          <Route path="/about-us" component={AboutUsPage} />
          <Route path="/blog-post" component={BlogPostPage} />
          <Route path="/blog-posts" component={BlogPostsPage} />
          <Route path="/components" component={ComponentsPage} />
          <Route path="/contact-us" component={ContactUsPage} />
          <Route path="/error-page" component={ErrorPage} />
          <Route path="/landing-page" component={LandingPage} />
          <Route path="/login-page" component={LoginPage} />
          <Route path="/product-page" component={ProductPage} />
          <Route path="/profile-page" component={ProfilePage} />
          <Route path="/signup-page" component={SignupPage} />
          <Route path="/users-page" component={UsersPage} />
          <Route path="/state-page" component={StatePage} />
          <Route path="/project/:id" component={ProjectPage} />
          <Route path="/contract/:id" component={ContractPage} />
          <Route path="/search/" component={SearchPage} />
          <Route path="/callback" component={CallbackPage} />
          <Route exact path="/" component={WelcomePage} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </CustomAuthProvider>
    </BrowserRouter>
  )
}

ReactDOM.render(
  <Provider store={store}>
    {/* <ConnectedRouter history={hist}> */}
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme} >
        <CssBaseline />
        <MessageSnackbar />
        <App />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
    {/* </ConnectedRouter> */}
  </Provider>
  ,
  document.getElementById("root")
);
