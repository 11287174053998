import React from 'react';
import Card from '@material-ui/core/Card';
import CardBody from "components/Card/CardBody.js";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
// import PieChart from "@material-ui/icons/PieChartOutlined";
// import AccountTreeOutlined from "@material-ui/icons/AccountTreeOutlined";
import { Avatar, CardMedia } from '@material-ui/core';
import ContractIcon from 'components/Icons/ContractIcon';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    card: {
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardBody: {
        width:'100%',
        padding: 0,
    },
    cardMedia: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    cardCategory: {
        marginTop: "10px",
        "& svg": {
            position: "relative",
            top: "8px"
        },
        fontWeight: 500
    },
    cardDescription: {
        color: theme.palette.grey[500],
        fontSize: 14
    },
    iconSmall: {
        fontSize: '1.5em',
        marginBottom: '-4px',
        marginRight: '5px',
        color: "#4d5156",
    },
    iconLabel: {
        color: "#4d5156",
        fontWeight: 400,
        fontSize: '1.1em'
    },
    selectedTitle: {
        margin: "0rem 0 0 !important",
        marginBottom: 20,
        minHeight: "50px",
        "& a": {
            textDecoration: 'none'
        },
        fontWeight: 400,
        color: '#4d5156'
    },

    avatar: {
        color: theme.palette.common.white,
        backgroundColor: green[400],//400 instead of 500 for a slightly muted hue
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    avatarIcon: {
        fontSize: '24px',
    },
    spacer: {
        height: theme.spacing(2),
    }
}));

const ContractCard = ({ contract, displayIcon }) => {
    const classes = useStyles();

    return (
        <Card variant="outlined" className={classes.card}>
            {displayIcon &&
                <CardMedia className={classes.cardMedia}>
                    <Avatar className={classes.avatar}>
                        <ContractIcon className={classes.avatarIcon} />
                    </Avatar>
                </CardMedia>
            }
            <CardBody className={classes.cardBody}>

                <Typography variant="caption" display="block">
                    {contract.Code}
                </Typography>

                <Typography className={classes.selectedTitle}>
                    <Link component={RouterLink} to={"/contract/" + contract.Id}>
                        {contract.Name.length > 25 ? contract.Name.substring(0, 25) + "..." : contract.Name}
                    </Link>
                </Typography>

                {/* {!displayIcon &&
                    <div className={classes.spacer} />
                }

                <Typography variant="caption">
                    <PieChart className={classes.iconSmall} style={{ marginLeft: 0 }} /><span className={classes.iconLabel}>{contract.MarketSegmentCount || 0}</span>
                    <AccountTreeOutlined className={classes.iconSmall} style={{ marginLeft: 10 }} /><span className={classes.iconLabel}>{contract.NumberOfProjectsInContract || 0}</span>
                </Typography> */}

            </CardBody>
        </Card>
    );
}

export default ContractCard;