import { createSelector, createSlice } from '@reduxjs/toolkit';
import { switchActiveOnlyOff } from './searchActiveOnly';

//function to return a list of years from 1960 until the current year as strings in reverse order
function getYearsAsFacets() {
  let years = []
  for (let i = new Date().getFullYear(); i >= 1960; i--) {
    years.push({ value: i.toString() })
  }
  return years
}

export const initialState = {
  "ProjectBusinessUnitName": {
    name: "Studio",
    limit: 10,//the limit for the number of potential facets to be returned. Set high enough for each field to return all possible values.
    facets: null, //[{count: #, value:"client name"}]
    selected: [],
  },
  "ProjectMarketSegmentName": {
    name: "Market Segment",
    limit: 100,
    facets: null, //[{count: #, value:"client name"}]
    selected: [],
  },
  "ProjectClientName": {
    name: "Client",
    limit: 10000,
    facets: null, //[{count: #, value:"client name"}]
    selected: [],
  },
  "ProjectDirectorName": {
    name: "Project Director",
    limit: 10000,
    facets: null, //[{count: #, value:"client name"}]
    selected: [],
  },

  "ProjectManagerName": {
    name: "Project Manager",
    limit: 1000,
    facets: null, //[{count: #, value:"client name"}]
    selected: [],
  },
  "ActiveYears": {
    name: "Active During",
    limit: 1000,
    facets: getYearsAsFacets(), //[{count: #, value:"client name"}]
    selected: [],
  },
}

const sliceName = 'searchFilters';

//check if input year as a string is older than 5 years
function isOlderThanXYears(year, xNumberOfYears = 5) {
  let currentYear = new Date().getFullYear()
  return currentYear - year >= xNumberOfYears
}


const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    facetsRecieved: (state, { payload }) => {
      Object.entries(payload).forEach(([key, value]) => {
        const filter = state[key];
        if (filter) {
          if (filter.limit <= value.length) {//this can't be greater than the limit, but if it's equal it means that limit needs to be increased.
            console.warn(`Warning: The number of facets returned (${value.length}) is greater than or equal to ${key}'s limit (${filter.limit}). This may mean that some options are not displayed.`)
          }

          filter.facets = value;
        }
      });
    },
    _filterSelected: (state, { payload }) => {
      const { facetId, selected } = payload;
      if (state[facetId]) {
        state[facetId].selected = selected;
      }
    },
    clearFilters: (state,) => {
      Object.keys(state).forEach(key => {
        state[key] = initialState[key];
      });
    },
  },
})

export const { facetsRecieved, clearFilters } = slice.actions;
const { _filterSelected } = slice.actions;

export function filterSelected({ facetId, selected }) {
  return dispatch => {
    dispatch(_filterSelected({ facetId, selected }));
    if (facetId === "ActiveYears" && selected.length === 1 && isOlderThanXYears(selected[0].value)) {
      dispatch(switchActiveOnlyOff());
    }
  }
}

export const selector = state => state[sliceName];

export const filtersActiveSelector = createSelector(
  selector,
  state => {
    let found = 0;
    Object.keys(state).forEach(key => {
      if (state[key].selected.length > 0) {
        console.log(state[key])
        found++;
      }
    });
    return found;
  }
)

export default slice.reducer

