import { createSlice } from '@reduxjs/toolkit';
import { postMessage } from 'store/slices/messages/messages';
import { GetToken } from 'CustomAuthProvider';
import userService from '../auth/user-service';

export const initialState = {
    hasErrors: false,
    loading: false,
}

const sliceName = 'favouritingProject';

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setStarting: state => {
            state.hasErrors = false;
            state.loading = true;
        },
        setSuccess: (state, { payload }) => {
            state.loading = false;
            state.hasErrors = false;
        },
        setFailure: state => {
            state.loading = false;
            state.hasErrors = true;
        },
    },
})

export const { setStarting, setSuccess, setFailure, } = slice.actions
export const selectedProjectEditsSelector = state => state[sliceName];
export default slice.reducer

export const favouriteProject = (projectInfo) => {
    return async (dispatch, getState) => {
        try {// just an example, probs unnesecary for something local.
            dispatch(setStarting());

            const tokenPromise = GetToken();
            const token = await tokenPromise;
            const currentUserId = getState().auth.user._id;

            const results = await userService.favouriteProject(token.idToken, currentUserId, projectInfo);
            console.log(results);
            dispatch(setSuccess());
        } catch (error) {
            dispatch(setFailure());
            postMessage({ type: "warning", text: "Favouriting project Error: " + error });
        }
    }
}


