import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  errors: [],
  hasErrors: false,
  page: 1,
}

const sliceName = 'searchResultsPage';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSearchResultsPageInternal: (state, { payload }) => {
      state.page = payload
      state.hasErrors = false
    },
    setSearchResultsPageFailure: (state, { payload }) => {
      state.hasErrors = true;
      state.errors.push(payload);
    },
  }
})

export const { setSearchResultsPageInternal, setSearchResultsPageFailure, } = slice.actions
export const searchSelector = state => state[sliceName];
export default slice.reducer

//also triggers suggestions fetching
export const setSearchResultsPage = page => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setSearchResultsPageInternal(page))
    } catch (error) {
      dispatch(setSearchResultsPageFailure(error + ""))
    }
  }
}