import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  errors: [],
  hasErrors: false,
  searchFields: "ProjectName,ProjectClientName"
}

const sliceName = 'searchFields';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSearchFieldsInternal: (state, { payload }) => {
      state.searchFields = payload
      state.hasErrors = false
    },
    setSearchFieldsFailure: (state, { payload }) => {
      state.hasErrors = true;
      state.errors.push(payload);
    },
  },
})

export const { setSearchFieldsInternal, setSearchFieldsFailure, } = slice.actions
export const searchSelector = state => state[sliceName];
export default slice.reducer

//also triggers suggestions fetching
export const setSearchFields = (searchFields) => {
  return async dispatch => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setSearchFieldsInternal(searchFields)) 
    } catch (error) {
      dispatch(setSearchFieldsFailure(error + ""))
    }
  }
}