/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Slider from '@material-ui/core/Slider';

import { useDispatch, useSelector } from 'react-redux'
import { displayNumSelector, setDisplayNum } from 'store/slices/posts/displayNum';

const useStyles = makeStyles(theme => ({
  slider: {
    margin: theme.spacing(0),
  },
}));

const StateSlider = props => {
  const dispatch = useDispatch();
  const { displayNum } = useSelector(displayNumSelector)
  const classes = useStyles(props);
  console.log('render slider')
  const handleSlider = (e, newValue) => {
    dispatch(setDisplayNum(newValue))
  }
  return (
      <Slider
        className={classes.slider}
        value={displayNum} 
        onChange={handleSlider}
        // step={10}
        // marks
        min={0}
        max={100}
      />

  );
}

export default StateSlider;