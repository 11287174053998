import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Small from "components/Typography/Small.js";
import Danger from "components/Typography/Danger.js";
import Warning from "components/Typography/Warning.js";
import Success from "components/Typography/Success.js";
import Info from "components/Typography/Info.js";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted.js";
import Quote from "components/Typography/Quote.js";
import SectionContentAreas from "./SectionContentAreas.js";

import typographyStyle from "./typographyStyle.js";

import image from "img/faces/avatar.jpg";

const useStyles = makeStyles(typographyStyle);

export default function SectionTypography() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="typography" className="cd-section">
          <div className={classes.title}>
            <Typography variant='h2'>Typography</Typography>
          </div>
          <GridContainer>
            <div className={classes.typo}>
              <div className={classes.note}>Header 1</div>
              <Typography variant='h1'>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 2</div>
              <Typography variant='h2'>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 3</div>
              <Typography variant='h3'>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 4</div>
              <Typography variant='h4'>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 5</div>
              <Typography variant='h5'>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 6</div>
              <Typography variant='h6'>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 1</div>
              <Typography variant='h1' className={classes.title}>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 2</div>
              <Typography variant='h2' className={classes.title}>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 3</div>
              <Typography variant='h3' className={classes.title}>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Header 4</div>
              <Typography variant='h4' className={classes.title}>The Life of Material Kit</Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Paragraph</div>
              <Typography variant='body1'>
                I will be the leader of a company that ends up being worth
                billions of dollars, because I got the answers. I understand
                culture. I am the nucleus. I think that’s a responsibility that
                I have, to push possibilities, to show people, this is the level
                that things could be at.
              </Typography>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Quote</div>
              <Quote
                text="I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at."
                author=" Kanye West, Musician"
              />
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Muted Text</div>
              <Muted>
                I will be the leader of a company that ends up being worth
                billions of dollars, because I got the answers...
              </Muted>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Primary Text</div>
              <Primary>
                I will be the leader of a company that ends up being worth
                billions of dollars, because I got the answers...
              </Primary>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Info Text</div>
              <Info>
                I will be the leader of a company that ends up being worth
                billions of dollars, because I got the answers...
              </Info>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Success Text</div>
              <Success>
                I will be the leader of a company that ends up being worth
                billions of dollars, because I got the answers...
              </Success>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Warning Text</div>
              <Warning>
                I will be the leader of a company that ends up being worth
                billions of dollars, because I got the answers...
              </Warning>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Danger Text</div>
              <Danger>
                I will be the leader of a company that ends up being worth
                billions of dollars, because I got the answers...
              </Danger>
            </div>
            <div className={classes.typo}>
              <div className={classes.note}>Small Tag</div>
              <Typography variant='h2'>
                Header with small subtitle
                <br />
                <Small>
                  Use {'"'}Small{'"'} tag for the headers
                </Small>
              </Typography>
            </div>
          </GridContainer>
        </div>
        <div className={classes.space50} />
        <div id="images">
          <div className={classes.title}>
            <Typography variant='h2'>Images</Typography>
          </div>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <Typography variant='h4'>Rounded Image</Typography>
              <img
                src={image}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={12} sm={2} className={classes.marginLeft}>
              <Typography variant='h4'>Circle Image</Typography>
              <img
                src={image}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={12} sm={2} className={classes.marginLeft}>
              <Typography variant='h4'>Rounded Raised</Typography>
              <img
                src={image}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
            <GridItem xs={12} sm={2} className={classes.marginLeft}>
              <Typography variant='h4'>Circle Raised</Typography>
              <img
                src={image}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRoundedCircle +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
          </GridContainer>
          <GridContainer />
        </div>
        <div className={classes.space50} />
        <SectionContentAreas />
      </div>
    </div>
  );
}
