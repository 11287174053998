import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { FilterList } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActiveSelector } from 'store/slices/search/searchFilters';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';
import { clearFilters } from 'store/slices/search/searchFilters';
import { getSearch } from 'store/slices/search/searchResults';

const useStyles = makeStyles(theme => ({
    filterListIcon: {
        marginRight: theme.spacing(1),
    },
}));

export function FilterToggle({ selected, onChange }) {

    const activeFilters = useSelector(filtersActiveSelector)

    const classes = useStyles();
    return (
        <ToggleButton
            value="check"
            selected={selected}
            onChange={onChange}
            style={{
                alignSelf: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

            }}
        >
            <FilterList className={classes.filterListIcon} />
            <span style={{ whiteSpace: 'nowrap' }}>
                {activeFilters ? `Filters (${activeFilters})` : "Filters"}
            </span>
        </ToggleButton>
    );
}

const useNewStyles = makeStyles(theme => ({
    clearButton: {
        marginLeft: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

export function ClearFilters() {
    const activeFilters = useSelector(filtersActiveSelector);
    const newClasses = useNewStyles();
    const dispatch = useDispatch();
    return (
        <>
            {activeFilters > 0 &&
                <Button onClick={() => { dispatch(clearFilters()); dispatch(getSearch()); }} className={newClasses.clearButton} variant="text" style={{ whiteSpace: 'nowrap' }}>
                    <Typography variant="button" >
                        Clear ✖
                    </Typography>
                </Button>
            }
        </>
    )
}
