import { createSlice } from '@reduxjs/toolkit'
import usersApi from './user-service';

export const initialState = {
  users: {},
  usersLoading: false,
  usersErrors: {},
}

const sliceName = 'users';

const usersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getUsersLoading: state => {
      state.loading = true
    },
    getUsersSuccess: (state, { payload }) => {
      state.users = payload
      state.usersLoading = false
      state.usersErrors = false
    },
    getUsersFailure: state => {
      state.usersLoading = false
      state.usersErrors = true
    },
  }
})

export const { getUsersLoading, getUsersSuccess, getUsersFailure, } = usersSlice.actions;
export const usersSelector = state => state[sliceName];
export default usersSlice.reducer

export function fetchUsers(idToken) {
  return async (dispatch) => {
    dispatch(getUsersLoading())
    try {
      const response = await usersApi.getUsers(idToken);
      if (response.err) { throw Error(response.err + ""); }
      else if (response.users) {
        dispatch(getUsersSuccess(response.users))
      }
      else {
        throw Error('unknown failure')
      }
    } catch (error) {
      console.log(error);
      dispatch(getUsersFailure())
    }
  }
}

export function changeRole(idToken, proposedUserId, proposedRole) {
  return async (dispatch) => {
    dispatch(getUsersLoading())
    try {
      const response = await usersApi.changeRole(idToken, proposedUserId, proposedRole);
      if (response.err) { throw Error(response.err + ""); }
      else if (response.users) {
        dispatch(getUsersSuccess(response.users))
      }
      else {
        throw Error('unknown failure')
      }
    } catch (error) {
      console.log(error);
      dispatch(getUsersFailure())
    }
  }
}
