/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons
import Mail from "@material-ui/icons/Mail";
import Favorite from "@material-ui/icons/Favorite";
import Small from 'components/Typography/Small';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";

import face1 from "img/faces/card-profile6-square.jpg";
import face2 from "img/faces/christian.jpg";
import face3 from "img/faces/card-profile4-square.jpg";
import face4 from "img/faces/card-profile1-square.jpg";
import face5 from "img/faces/marc.jpg";
import face6 from "img/faces/kendall.jpg";
import face7 from "img/faces/card-profile5-square.jpg";
import face8 from "img/faces/card-profile2-square.jpg";

import styles from "./sectionFooterStyle.js";

const useStyles = makeStyles(styles);

export default function SectionFooter() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant='h3'>Footer Areas</Typography>
        </div>
      </div>
      <div>
        <Footer
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <Link
                  href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.footerBrand}
                >
                  Material Kit PRO React
                </Link>
              </div>
              <div className={classes.pullCenter}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      Creative Tim
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/presentation?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      About us
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="//blog.creative-tim.com/"
                      className={classes.block}
                    >
                      Blog
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/license?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      Licenses
                    </Link>
                  </ListItem>
                </List>
              </div>
              <div className={classes.rightLinks}>
                <ul>
                  <li>
                    <Button
                      href="https://twitter.com/CreativeTim?ref=creativetim"
                      target="_blank"
                      color="twitter"
                      justIcon
                      simple
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="https://dribbble.com/creativetim?ref=creativetim"
                      target="_blank"
                      color="dribbble"
                      justIcon
                      simple
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="https://instagram.com/CreativeTimOfficial?ref=creativetim"
                      target="_blank"
                      color="google"
                      justIcon
                      simple
                    >
                      <i className="fab fa-google-plus-g" />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          }
        />
        <br />
        <br />
        <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      Creative Tim
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/presentation?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      About us
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="//blog.creative-tim.com/"
                      className={classes.block}
                    >
                      Blog
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/license?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      Licenses
                    </Link>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <Link
                  href="https://www.creative-tim.com?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.aClasses}
                >
                  Creative Tim
                </Link>{" "}
                for a better web.
              </div>
            </div>
          }
        />
        <br />
        <br />
        <Footer
          theme="dark"
          content={
            <div>
              <div className={classes.left}>
                <Link
                  href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.footerBrand}
                >
                  Material Kit PRO React
                </Link>
              </div>
              <div className={classes.pullCenter}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="http://blog.creative-tim.com/?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      Blog
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/presentation?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      Presentation
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="#pablito"
                      onClick={e => e.preventDefault()}
                      className={classes.block}
                    >
                      Discover
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="#pablito"
                      onClick={e => e.preventDefault()}
                      className={classes.block}
                    >
                      Payment
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="https://www.creative-tim.com/contact-us?ref=mkpr-footer-components"
                      target="_blank"
                      className={classes.block}
                    >
                      Contact us
                    </Link>
                  </ListItem>
                </List>
              </div>
              <div className={classes.rightLinks}>
                <ul>
                  <li>
                    <Button
                      href="https://twitter.com/CreativeTim?ref=creativetim"
                      target="_blank"
                      color="white"
                      justIcon
                      simple
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="https://dribbble.com/creativetim?ref=creativetim"
                      target="_blank"
                      color="white"
                      justIcon
                      simple
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="https://instagram.com/CreativeTimOfficial?ref=creativetim"
                      target="_blank"
                      color="white"
                      justIcon
                      simple
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          }
        />
        <br />
        <br />
        <Footer
          theme="dark"
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="#pablo"
                      className={classes.block}
                      onClick={e => e.preventDefault()}
                    >
                      Blog
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="#pablo"
                      className={classes.block}
                      onClick={e => e.preventDefault()}
                    >
                      Presentation
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="#pablo"
                      className={classes.block}
                      onClick={e => e.preventDefault()}
                    >
                      Discover
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="#pablo"
                      className={classes.block}
                      onClick={e => e.preventDefault()}
                    >
                      Payment
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link
                      href="#pablo"
                      className={classes.block}
                      onClick={e => e.preventDefault()}
                    >
                      Contact Us
                    </Link>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                Copyright &copy; {1900 + new Date().getYear()}{" "}
                <Link
                  href="https://www.creative-tim.com?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.aClasses}
                >
                  Creative Tim
                </Link>{" "}
                All Rights Reserved.
              </div>
            </div>
          }
        >
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Typography variant='h5'>About Us</Typography>
              <Typography variant='body1'>
                Creative Tim is a startup that creates design tools that make
                the web development process faster and easier.{" "}
              </Typography>
              <Typography variant='body1'>
                We love the web and care deeply for how users interact with a
                digital product. We power businesses and individuals to create
                better looking web projects around the world.{" "}
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Typography variant='h5'>Social Feed</Typography>
              <div className={classes.socialFeed}>
                <div>
                  <i className="fab fa-twitter" />
                  <Typography variant='body1'>How to handle ethical disagreements with your clients.</Typography>
                </div>
                <div>
                  <i className="fab fa-twitter" />
                  <Typography variant='body1'>The tangible benefits of designing at 1x pixel density.</Typography>
                </div>
                <div>
                  <i className="fab fa-facebook-square" />
                  <Typography variant='body1'>
                    A collection of 25 stunning sites that you can use for
                    inspiration.
                  </Typography>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Typography variant='h5'>Instagram Feed</Typography>
              <div className={classes.galleryFeed}>
                <img
                  src={face1}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded
                  )}
                  alt="..."
                />
                <img
                  src={face2}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded
                  )}
                  alt="..."
                />
                <img
                  src={face3}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded
                  )}
                  alt="..."
                />
                <img
                  src={face4}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded
                  )}
                  alt="..."
                />
                <img
                  src={face5}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded
                  )}
                  alt="..."
                />
                <img
                  src={face6}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded
                  )}
                  alt="..."
                />
                <img
                  src={face7}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded
                  )}
                  alt="..."
                />
                <img
                  src={face8}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded
                  )}
                  alt="..."
                />
              </div>
            </GridItem>
          </GridContainer>
        </Footer>
        <br />
        <br />
        <Footer
          theme="white"
          content={
            <div className={classNames(classes.pullCenter, classes.copyRight)}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <Link
                href="https://www.creative-tim.com?ref=mkpr-footer-components"
                target="_blank"
              >
                Creative Tim
              </Link>{" "}
              All Rights Reserved.
            </div>
          }
        >
          <div className={classes.footer}>
            <GridContainer>
              <GridItem xs={12} sm={2} md={2}>
                <Typography variant='h5'>About Us</Typography>
                <ul className={classes.linksVertical}>
                  <li>
                    <Link href="#pablo">Blog</Link>
                  </li>
                  <li>
                    <Link href="#pablo">About us</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Presentation</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Contact Us</Link>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <Typography variant='h5'>Market</Typography>
                <ul className={classes.linksVertical}>
                  <li>
                    <Link href="#pablo">Sales FAQ</Link>
                  </li>
                  <li>
                    <Link href="#pablo">How to register</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Sell goods</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Receive Payment</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Transactions issues</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Affiliates program</Link>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Typography variant='h5'>Social Feed</Typography>
                <div className={classes.socialFeed}>
                  <div>
                    <i className="fab fa-twitter" />
                    <Typography variant='body1'>
                      How to handle ethical disagreements with your clients.
                    </Typography>
                  </div>
                  <div>
                    <i className="fab fa-twitter" />
                    <Typography variant='body1'>
                      The tangible benefits of designing at 1x pixel density.
                    </Typography>
                  </div>
                  <div>
                    <i className="fab fa-facebook-square" />
                    <Typography variant='body1'>
                      A collection of 25 stunning sites that you can use for
                      inspiration.
                    </Typography>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Typography variant='h5'>Follow Us</Typography>
                <ul className={classes.socialButtons}>
                  <li>
                    <Button justIcon simple href="#pablo" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                  </li>
                  <li>
                    <Button justIcon simple href="#pablo" color="facebook">
                      <i className="fab fa-facebook-square" />
                    </Button>
                  </li>
                  <li>
                    <Button justIcon simple href="#pablo" color="dribbble">
                      <i className="fab fa-dribbble" />
                    </Button>
                  </li>
                  <li>
                    <Button justIcon simple href="#pablo" color="google">
                      <i className="fab fa-google-plus-g" />
                    </Button>
                  </li>
                  <li>
                    <Button justIcon simple href="#pablo" color="instagram">
                      <i className="fab fa-instagram" />
                    </Button>
                  </li>
                </ul>
                <Typography variant='h5'>Numbers Don{"'"}t Lie</Typography>
                <Typography variant='h4'>
                  14.521 <Small>Freelancers</Small>
                </Typography>
                <Typography variant='h4'>
                  1.423.183 <Small>Transactions</Small>
                </Typography>
              </GridItem>
            </GridContainer>
          </div>
        </Footer>
        <br />
        <br />
        <Footer
          theme="white"
          content={
            <div>
              <ul className={classes.socialButtons}>
                <li>
                  <Button justIcon simple href="#pablo" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple href="#pablo" color="facebook">
                    <i className="fab fa-facebook-square" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple href="#pablo" color="dribbble">
                    <i className="fab fa-dribbble" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple href="#pablo" color="google">
                    <i className="fab fa-google-plus-g" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple href="#pablo" color="youtube">
                    <i className="fab fa-youtube" />
                  </Button>
                </li>
              </ul>
              <div
                className={classNames(classes.pullCenter, classes.copyRight)}
              >
                Copyright &copy; {1900 + new Date().getYear()}{" "}
                <Link
                  href="https://www.creative-tim.com?ref=mkpr-footer-components"
                  target="_blank"
                >
                  Creative Tim
                </Link>{" "}
                All Rights Reserved.
              </div>
            </div>
          }
        >
          <div className={classes.footer}>
            <GridContainer>
              <GridItem xs={12} sm={3} md={3}>
                <Link href="#pablo">
                  <Typography variant='h5'>Material Kit PRO</Typography>
                </Link>
                <Typography variant='body1'>
                  Probably the best UI Kit in the world! We know you{"'"}ve been
                  waiting for it, so don{"'"}t be shy!
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <Typography variant='h5'>About</Typography>
                <ul className={classes.linksVertical}>
                  <li>
                    <Link href="#pablo">Blog</Link>
                  </li>
                  <li>
                    <Link href="#pablo">About us</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Presentation</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Contact us</Link>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <Typography variant='h5'>Market</Typography>
                <ul className={classes.linksVertical}>
                  <li>
                    <Link href="#pablo">Sales FAQ</Link>
                  </li>
                  <li>
                    <Link href="#pablo">How to register</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Sell Goods</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Receive Payment</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Transactions Issues</Link>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <Typography variant='h5'>Legal</Typography>
                <ul className={classes.linksVertical}>
                  <li>
                    <Link href="#pablo">Transactions FAQ</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Terms & conditions</Link>
                  </li>
                  <li>
                    <Link href="#pablo">Licenses</Link>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <Typography variant='h5'>Subscribe to Newsletter</Typography>
                <Typography variant='body1'>
                  Join our newsletter and get news in your inbox every week! We
                  hate spam too, so no worries about this.
                </Typography>
                <form>
                  <CustomInput
                    id="footeremail"
                    formControlProps={{
                      fullWidth: false,
                      className: classes.customFormControl
                    }}
                    inputProps={{
                      placeholder: "Your Email..."
                    }}
                  />
                  <Button color="primary" justIcon>
                    <Mail />
                  </Button>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </Footer>
      </div>
    </div>
  );
}
