import { Accordion, AccordionDetails, AccordionSummary as MuiAccordionSummary, Collapse, List, ListItem, Typography, withStyles } from '@material-ui/core';
import { ExpandLess, Notifications } from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { useDropdownStyles } from 'components/CustomButtons/LoginButton';
import React from 'react';
import { useSelector } from 'react-redux';
import { messagesSelector } from 'store/slices/messages/messages';

const useNotificationStyles = makeStyles((theme) => ({
    expanded: {
        '&$expanded': {
            margin: 'auto'
        }
    },
}));

const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        // borderBottom: '1px solid rgba(0, 0, 0, .125)',
        // marginBottom: -1,
        minHeight: 44,
        '&$expanded': {
            minHeight: 44,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function NotificationList() {
    const notificationClasses = useNotificationStyles();
    const dropDownClasses = useDropdownStyles();
    const notificationsList = useSelector(messagesSelector);
    const notificationKeys = Object.keys(notificationsList).reverse();
    console.log(notificationsList);

    return (
        <Accordion style={{ padding: '0px', margin: '0px', width: '100%', }} >
            <AccordionSummary onClick={e => e.stopPropagation()} style={{ padding: '0px', margin: '0px' }} classes={{ expanded: notificationClasses.expanded }} >
                <div
                    className={dropDownClasses.dropdownLink}
                    style={{ paddingTop: '0px', paddingBottom: '0px' }}
                >
                    <Notifications className={dropDownClasses.dropdownIcons} />
                    Notifications
                </div>
            </AccordionSummary>
            <AccordionDetails style={{ margin: 0, marginLeft: 50, padding: 0 }} >
                {notificationKeys.length === 0 &&
                    <div style={{ marginBottom: 12 }}>
                        None
                    </div>
                }
                {notificationKeys.length > 0 &&
                    <List style={{ margin: 0, marginBottom: 12, padding: 0 }}>
                        {notificationKeys.map((key, index) => {
                            const notification = notificationsList[key];
                            return (
                                <ListItem
                                    key={key}
                                    style={{ paddingLeft: 0 }}
                                >
                                    <Typography variant="caption" key={key} style={{ marginLeft: 0 }}>
                                        {notification.text}
                                    </Typography>
                                </ListItem>
                            )
                        })}
                    </List>
                }

            </AccordionDetails>
        </Accordion>

    )
}

export function MobileNotificationList() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(!open);
    };
    const notificationsList = useSelector(messagesSelector);
    const notificationKeys = Object.keys(notificationsList).reverse();

    return (
        <>
            <ListItem button style={{ color: 'inherit' }} onClick={handleOpen}>
                Notifications
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" >
                {notificationKeys.length === 0 &&
                    <div style={{ marginBottom: 12, marginLeft:16 }}>
                        None
                    </div>
                }
                {notificationKeys.length > 0 &&
                    <List component="div" disablePadding>
                        {notificationKeys.map((key, index) => {
                            const notification = notificationsList[key];
                            return (
                                <ListItem
                                    key={key}
                                    style={{ paddingLeft: 0 }}
                                >
                                    <Typography variant="caption" key={key} style={{ marginLeft: 16 }}>
                                        {notification.text}
                                    </Typography>
                                </ListItem>
                            )
                        })}
                    </List>
                }
            </Collapse>
        </>
    )
}