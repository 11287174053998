
import getFetchOptions from 'store/utils/fetchOptions';
const baseAPI = '/api/';

const editTags = async (idToken, ProjectId, Tags) => {
  try {
    console.log(Tags);

    const body = {
      fieldName: 'Tags',
      documentId: ProjectId,
      value: Tags,
    }
    const response = await fetch(`${baseAPI}/project/edit`,
      getFetchOptions('POST', 'application/json', body, true, idToken))
      .then(response => {
        if (!response.ok) {
          throw (Error(response.status + " " + response.statusText));
        }
        return response;
      });
    const parsedResponse = await response.json();
    console.log(parsedResponse)
    return parsedResponse;
  }
  catch (err) {
    throw err;
  };
};

const editProject = async (idToken, ProjectId, fieldName, fieldValue) => {
  try {
    const body = {
      fieldName: fieldName,
      documentId: ProjectId,
      value: fieldValue,
    }
    const response = await fetch(`${baseAPI}/project/edit`,
      getFetchOptions('POST', 'application/json', body, true, idToken))
      .then(response => {
        if (!response.ok) {
          throw (Error(response.status + " " + response.statusText));
        }
        return response;
      });
    const parsedResponse = await response.json();
    console.log(parsedResponse)
    return parsedResponse;
  }
  catch (err) {
    throw err;
  };
};


const getEdits = async (idToken, ProjectId) => {
  try {
    const response = await fetch(`${baseAPI}/project/${ProjectId}/edits`,
      getFetchOptions('GET', 'application/json', null, true, idToken))
      .then(response => {
        if (!response.ok) {
          throw (Error(response.status + " " + response.statusText));
        }
        return response;
      });
    const parsedResponse = await response.json();
    console.log(parsedResponse)
    return parsedResponse;
  }
  catch (err) {
    throw err;
  };
};

const projectService = {
  editTags,
  editProject,
  getEdits,
};

export default projectService;