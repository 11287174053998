/*eslint-disable*/
import React, { useMemo } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import PostsList from './PostsList';
import { useSelector } from 'react-redux'

import { displayNumSelector } from 'store/slices/posts/displayNum';

const useStyles = makeStyles(theme => ({
  postsListPane: {
    alignItems: 'center',
    maxHeight: props => (props.listHeight + 'vh'),
    overflowY: 'scroll',
    position: 'sticky',
    top: '10vh',
  },
}));

const PostsListPane = props => {
  const { displayNum } = useSelector(displayNumSelector);
  console.log('rendering postslist pane')
  const classes = useStyles({ ...props, listHeight: displayNum });

  // by putting our list of posts in a memo, it won't rerender unless a variable
  // passed in the second argument changes. Essentially the first time this component 
  // is created, React will execute that memo and get the value (in this case our 
  // <PostsList/> object). When React later goes to update this component, it will check to
  // see if the variables in the second argument have changed, if they haven't, it will 
  // reuse the value it computed the first time. Since we don't pass
  // any variables in that second argument, it will create a PostsLists object the first
  // time, and then whenever it goes to update this component, it will always return
  // that same PostsLists instead of react having to
  // recreate the whole list of post objects. This add a big performance boost
  // since this object is just here to link with the slider, and control the height
  // of the list with dynamic styling.
  const PerformantList = useMemo(() => (
    <PostsList />
  ), []);
  return (
    <div className={classes.postsListPane}>
      {PerformantList}
    </div>
  );
}

export default PostsListPane;