import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  posts: {},
  postsloading: false,
  postsErrors: {},
}

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    getPostsLoading: state => {
      state.loading = true
    },
    getPostsSuccess: (state, { payload }) => {
      state.posts = payload
      state.loading = false
      state.hasErrors = false
    },
    getPostsFailure: state => {
      state.loading = false
      state.hasErrors = true
    },

  }
})

export const { getPostsLoading, getPostsSuccess, getPostsFailure,  } = postsSlice.actions;
export const postsSelector = state => state.posts;
export default postsSlice.reducer

export function fetchPosts() {
  return async dispatch => {
    dispatch(getPostsLoading())

    try {
      const response = await fetch('https://jsonplaceholder.typicode.com/posts')
      const data = await response.json()
      const obj = {}
      data.forEach(post => obj[post.id] = post);
      dispatch(getPostsSuccess(obj))
    } catch (error) {
      dispatch(getPostsFailure())
    }
  }
}
