import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
    MdCancel,
    MdContentCopy,
    MdEdit,
    MdSave,
} from 'react-icons/md';
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton';
import copy from 'copy-to-clipboard';
import { postMessage } from 'store/slices/messages/messages';
import { useDispatch, useSelector } from 'react-redux'
import Link from 'components/routing/Link';
import { useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { editDirectory } from 'store/slices/projectPage/directoryEditing';
import { directoryEditingSelector } from 'store/slices/projectPage/directoryEditing';

const useStyles = makeStyles(theme => ({
    description: {
        overflow: "hidden",
        margin: "0",
        "& p": {
            color: '#4d5156',
            fontSize: "14px"
        },
        color: "#4d5156",
        fontSize: "14px",
        fontWeight: 400
    },
    clickable: {
        color: "#9c27b0"
    },
    hoverPointer: {
        cursor: 'pointer',
    },
}));


const DirectoryDisplay = ({ directory, editLink, hideCopyButton, editEnabled, projectId, matchAutoCompleteHeight }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false);
    const [directoryValue, setDirectoryValue] = useState(directory || "");
    const { loading } = useSelector(directoryEditingSelector);

    const userCopies = () => {
        copy(directory);
        dispatch(postMessage({ type: 'success', text: 'Copied to clipboard!', timeout: 3000 }));
    };
    return (
        <Grid container alignItems="center" spacing={1} >

            {/* groups the two buttons together */}
            {(!hideCopyButton || editEnabled) &&
                <Grid item >
                    {!loading &&
                        <Grid container>
                            {!hideCopyButton &&
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        aria-label="copy to clipboard"
                                        component="span"
                                        onClick={userCopies}>
                                        <MdContentCopy />
                                    </IconButton>
                                </Grid>
                            }
                            {editEnabled && !editMode &&
                                <Grid item>
                                    <IconButton size="small" aria-label="copy to clipboard" component="span" onClick={() => setEditMode(true)}>
                                        <MdEdit />
                                    </IconButton>
                                </Grid>
                            }
                            {editMode &&
                                <Grid item>
                                    <IconButton size="small" aria-label="copy to clipboard" component="span" onClick={() => setEditMode(false)}>
                                        <MdCancel />
                                    </IconButton>
                                </Grid>
                            }
                            {editMode &&
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        aria-label="copy to clipboard"
                                        component="span"
                                        onClick={() => {
                                            dispatch(editDirectory(projectId, directoryValue));
                                            setEditMode(false);
                                        }}
                                    >
                                        <MdSave />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    }
                    {loading &&
                        <CircularProgress size={10} />
                    }
                </Grid>
            }


            {/* our text as it's own item to increase spacing */}
            <Grid item style={{ flexGrow: 1, minHeight: matchAutoCompleteHeight ? '2.8em' : undefined, display:'flex', alignItems:'center' }}  >
                {!editMode && directory &&
                    <Typography className={clsx(classes.description, classes.hoverPointer, classes.clickable)} onClick={userCopies}>
                        {directory}
                    </Typography>
                }
                {!editMode && !directory && editLink &&
                    <Typography className={classes.description} >
                        We're having trouble finding the network folder for this project, click <Link to={editLink} style={{ textDecoration: 'none' }}> here </Link> to add one!
                    </Typography>
                }
                {!editMode && !directory && !editLink &&
                    <Typography className={classes.description} component="span"  >
                        We're having trouble finding the network folder for this project, <Typography className={classes.description} style={{cursor:'pointer'}} display="inline" onClick={() => setEditMode(true)} > click  here to add one</Typography>!
                    </Typography>
                }
                {editMode &&
                    <TextField autoFocus id="outlined-basic" margin="none" size='small' value={directoryValue} onChange={(e) => setDirectoryValue(e.target.value)} fullWidth />
                }

            </Grid>


        </Grid >

    );
}


export default DirectoryDisplay;