import React from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
//import { IoMdSquare } from 'react-icons/io';



const Carousel = props => {
    const arrowStyles = {
        position: 'absolute',
        color: '#ffffff80',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 35,
        height: 35,
        cursor: 'pointer',
    };
    const indent = 50;
    // const indicatorStyles = {
    //     display: 'inline-block',
    //     margin: '0 8px',
    //     cursor: 'pointer',
    //     verticalAlign: 'center',
    // };
    return (
        <ResponsiveCarousel
            swipeable={true}
            emulateTouch={true}
            infiniteLoop={true}
            dynamicHeight={false}
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            transitionTime={600}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <ArrowBackIos
                        style={
                            {
                                ...arrowStyles,
                                left: indent,
                            }}
                        onClick={onClickHandler}
                    />
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <ArrowForwardIos
                        style={
                            {
                                ...arrowStyles,
                                right: indent,
                            }}
                        onClick={onClickHandler}
                    />
                )
            }
            //currently not fully functioning due to an issue that makes clicking an indicator awkward
            // renderIndicator={(onClickHandler, isSelected, index, label) => {
            //     if (isSelected) {
            //         return (
            //             <li
            //                 style={{ ...indicatorStyles, width: 15, height: 15 }}
            //                 aria-label={`Selected: ${label} ${index + 1}`}
            //                 title={`Selected: ${label} ${index + 1}`}>
            //                 <IoMdSquare
            //                     size={15}
            //                     style={{
            //                         color: '#ffffff',
            //                     }}
            //                 />
            //             </li>
            //         );
            //     }
            //     return (
            //         <li
            //             style={{ ...indicatorStyles, width: 15, height: 15 }}
            //             onClick={onClickHandler}
            //             onKeyDown={onClickHandler}
            //             value={index}
            //             role="button"
            //             key={index}
            //             tabIndex={0}
            //             title={`${label} ${index + 1}`}
            //             aria-label={`${label} ${index + 1}`}
            //             zIndex={10}
            //         >

            //             <IoMdSquare
            //                 size={10}
            //                 onClick={
            //                     (e) => {
            //                         console.log('click mother')
            //                         onClickHandler(e)
            //                     }}
            //                 onKeyDown={onClickHandler}
            //                 style={{
            //                     color: '#ffffff',
            //                     verticalAlign: 'center',

            //                 }}
            //             />
            //         </li>
            //     );
            // }}
        >
            {props.children}
        </ResponsiveCarousel >
    )
};

export default Carousel;