import React from "react";
// react components for routing our app without refresh
import Link from "@material-ui/core/Link";
// nodejs library to set properties for components
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import styles from "./projectStyle.js";
import Event from "@material-ui/icons/EventOutlined";
import AccountBox from "@material-ui/icons/AccountBoxOutlined";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import Grid from '@material-ui/core/Grid';
import RouterLink from 'components/routing/Link';
import DirectoryDisplay from 'components/DirectoryDisplay/DirectoryDisplay';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import { selector as siblingProjectsSelector } from 'store/slices/projectPage/siblingProjects';
import { useSelector } from 'react-redux'
import { FolderOpen as FolderIcon, ExpandLess, ExpandMore } from "@material-ui/icons";
import RelatedProjectsCarousel from "components/Carousels/RelatedProjectsCarousel";
import clsx from 'clsx';
import { MarketSegmentIcon } from "components/Icons/MarketSegmentIcons.jsx";
import { Avatar, Chip, CircularProgress, Collapse, IconButton } from "@material-ui/core";
import { blue, } from "@material-ui/core/colors";

import { ServiceLineIcon } from "components/Icons/ServiceLineIcon.js";
import { ClientIcon } from "components/Icons/ClientIcon.js";
import ProjectTags from "./ProjectTags.jsx";
import { tagsEditingSelector } from "store/slices/projectPage/tagsEditing.js";
import { selectedProjectEditsSelector } from "store/slices/projectPage/selectedProjectEdits";
import { VscSymbolKeyword } from "react-icons/vsc"
import ContractIconOutlined from "components/Icons/ContractIconOutlined.js";
import MuiBadge from "components/Badge/MuiBadge.jsx";
import { useIsActiveProject } from 'hooks/useIsActiveProject';
import { MdEdit } from "react-icons/md";
import { History } from "@material-ui/icons";
import { AddToFavouritesButton } from "components/CustomButtons/AddToFavourites.jsx";

const useStyles = makeStyles(styles);

const useNewStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  avatarIcon: {
    fontSize: '36px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ProjectMain({ project }) {

  const classes = useStyles();
  const newClasses = useNewStyles();
  const { siblingProjects, loading: siblingProjectsFetching } = useSelector(siblingProjectsSelector);
  const siblingProjectsLength = Object.keys(siblingProjects).length;

  const activeProject = useIsActiveProject(project.ProjectStartDate, project.ProjectFinishDate);

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={3} md={2} lg={1}>
          <MuiBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent=" "
            variant='dot'
            color={activeProject ? "active" : "inactive"}
            size={16}
            borderWidth={2}
          >
            <Avatar className={newClasses.avatar}>
              <MarketSegmentIcon className={newClasses.avatarIcon} marketSegment={project.ProjectMarketSegmentName} />
            </Avatar>
          </MuiBadge>
        </Grid>
        <Grid item xs={9} md={10} lg={11}>
          <Typography gutterBottom variant='h5' className={classes.mainLabelHeader}>{project.ProjectCode}</Typography>
          <Typography variant='h2' className={classes.selectedTitle}>
            {project.ProjectName}
          </Typography>
        </Grid>
      </Grid>
      <AddToFavouritesButton project={{ ProjectId: project.ProjectId, ProjectName: project.ProjectName }} />
      <div style={{ height: '14px', width: '100%' }} />


      <div className={classes.cardDescriptionWhite}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ClientIcon className={classes.iconSmall} />
              </Grid>
              <Grid item xs>
                <Typography variant='h6' className={classes.smaller}>Client</Typography>
                <Typography className={classes.description}>
                  {project.ProjectClientName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ContractIconOutlined className={classes.iconSmall} />
              </Grid>
              <Grid item xs>
                <Typography variant='h6' className={classes.smaller}>Contract</Typography>
                <Typography className={classes.description}>
                  {(project.ContractName && project.Contract) &&
                    <Link component={RouterLink} to={"/contract/" + project.Contract}>
                      {project.ContractName + (project.ContractCode ? " | " + project.ContractCode : "")}
                    </Link>
                  }
                  {!(project.ContractName && project.Contract) &&
                    "Not found"
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <MarketSegmentIcon className={classes.iconSmall} marketSegment={project.ProjectMarketSegmentName} />
              </Grid>
              <Grid item xs>
                <Typography variant='h6' className={classes.smaller}>Market Segment</Typography>
                <Typography className={classes.description}>
                  {project.ProjectMarketSegmentName || "Not found"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ColorLensOutlinedIcon className={classes.iconSmall} />
              </Grid>
              <Grid item xs>
                <Typography variant='h6' className={classes.smaller}>Studio</Typography>
                <Typography className={classes.description}>
                  {project.ProjectBusinessUnitName || "Not found"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ServiceLineIcon className={classes.iconSmall} />
              </Grid>
              <Grid item xs>
                <Typography variant='h6' className={classes.smaller}>Service Line</Typography>
                <Typography className={classes.description}>
                  {project.ServiceLineName || "Not found"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <Event className={classes.iconSmall} />
              </Grid>
              <Grid item xs>
                <Typography variant='h6' className={classes.smaller}>Project Dates</Typography>
                <Typography className={classes.description}>
                  {new Date(project.ProjectStartDate).toISOString().split('T')[0]} to {new Date(project.ProjectFinishDate).toISOString().split('T')[0]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>




        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <AccountBox className={classes.iconSmall} />
              </Grid>
              <Grid item xs>
                <Typography variant='h6' className={classes.smaller}>Project Manager</Typography>
                <Typography className={classes.description}>
                  <span dangerouslySetInnerHTML={{
                    __html:
                      project['@search.highlights'] && project['@search.highlights'].ProjectManagerName ? project['@search.highlights'].ProjectManagerName : project.ProjectManagerName
                  }}>
                  </span>{", "}
                  <span dangerouslySetInnerHTML={{
                    __html:
                      project['@search.highlights'] && project['@search.highlights'].ProjectManagerHomeOrgName ? project['@search.highlights'].ProjectManagerHomeOrgName : project.ProjectManagerHomeOrgName
                  }}>
                  </span>
                </Typography>

                <>
                  <Typography variant='h6' className={classes.smaller}>Principal In Charge</Typography>
                  <Typography className={classes.description}>
                    <span dangerouslySetInnerHTML={{
                      __html:
                        project['@search.highlights'] && project['@search.highlights'].ProjectDirectorName ? project['@search.highlights'].ProjectDirectorName : project.ProjectDirectorName
                    }}>
                    </span>{", "}
                    <span dangerouslySetInnerHTML={{
                      __html:
                        project['@search.highlights'] && project['@search.highlights'].ProjectDirectorHomeOrgName ? project['@search.highlights'].ProjectDirectorHomeOrgName : project.ProjectDirectorHomeOrgName
                    }}>
                    </span>
                  </Typography>

                </>
                <>
                  <Typography variant='h6' className={classes.smaller}>Project Organization</Typography>
                  <Typography className={classes.description}>
                    <span dangerouslySetInnerHTML={{
                      __html:
                        project['@search.highlights'] && project['@search.highlights'].ProjectOrgName ? project['@search.highlights'].ProjectOrgName : project.ProjectOrgName
                    }}>
                    </span>
                  </Typography>
                </>
                <>
                  <Typography variant='h6' className={classes.smaller}>Related Projects</Typography>
                  <Typography className={classes.description}>
                    {project.RelatedProjects.length === 0 &&
                      "No related projects found"
                    }
                    {project.RelatedProjects.map((related, index, array) => {
                      return (
                        <Link key={index} component={RouterLink} to={"/Project/" + related.Key} className={classes.link}>
                          {related.Value}<br></br>
                        </Link>
                      )
                    })
                    }
                  </Typography>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <AssignmentTurnedInOutlined className={classes.iconSmall} />
              </Grid>
              <Grid item xs>
                <Typography variant='h6' className={classes.smaller}>Task Managers</Typography>
                <Typography className={classes.description}>
                  <span dangerouslySetInnerHTML={{
                    __html:
                      project['@search.highlights'] && project['@search.highlights'].TaskManagers ? project['@search.highlights'].TaskManagers[0] : (project.TaskManagers) ? project.TaskManagers : "No task managers found"
                  }}>
                  </span>
                </Typography>

                <>
                  <Typography variant='h6' className={classes.smaller}>Task Organizations</Typography>
                  <Typography className={classes.description}>
                    <span dangerouslySetInnerHTML={{
                      __html:
                        project['@search.highlights'] && project['@search.highlights'].TaskOrgs ? project['@search.highlights'].TaskOrgs[0] : (project.TaskOrgs) ? project.TaskOrgs : "No task organizations found "
                    }}>
                    </span>v
                  </Typography>

                  <Typography variant='h6' className={classes.smaller}>Task Resources</Typography>
                  <Typography className={classes.description}>
                    <span dangerouslySetInnerHTML={{
                      __html:
                        project['@search.highlights'] && project['@search.highlights'].Resources ? project['@search.highlights'].Resources[0] : (project.Resources) ? project.Resources : "No resources found "
                    }}>
                    </span>
                  </Typography>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item xs={12} >
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                  <FolderIcon className={classes.iconSmall} />
                </Grid>
                <Grid item xs>
                  <Typography variant='h6' className={classes.smaller}>Network Folder</Typography>
                  <DirectoryDisplay
                    directory={project.NetworkDirectory_current}
                    projectId={project.ProjectId}
                    hideCopyButton
                    editEnabled
                    matchAutoCompleteHeight
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={10}>
            <ProjectTagsWithTitle tags={project?.Tags_current || []} />
          </Grid>

          <Grid item container spacing={1}>
            <ProjectEditHistory />
          </Grid>
        </Grid>

        <Typography variant="body1" className={clsx(classes.descriptionStrong, classes.heading)} >
          {"Other Projects From The Same Contract "} {(siblingProjects && !siblingProjectsFetching) ? "(" + (siblingProjectsLength === 500 ? "Top " : "") + siblingProjectsLength + ")" : "0"}
        </Typography>
        <RelatedProjectsCarousel items={siblingProjects} loading={siblingProjectsFetching} numberOfCards={5} />

      </div>
    </>
  );
}


function ProjectEditHistory({ project }) {
  const classes = useStyles();

  const { edits, loading, } = useSelector(selectedProjectEditsSelector)
  const [open, setOpen] = React.useState(false);
  function handleOpen() {
    setOpen(!open);
  }

  return (
    <Grid item xs={12} >
      <Grid container wrap="nowrap" spacing={1}>
        <Grid item>
          <History className={classes.iconSmall} />
        </Grid>
        <Grid item xs>
          <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
            <Typography variant='h6' className={classes.smaller} display="inline">Edit History </Typography>
            {open ? <ExpandLess className={classes.iconSmall} /> : <ExpandMore className={classes.iconSmall} />}
          </div>
          {loading &&
            <CircularProgress size={"1.5rem"} />
          }
          <Collapse in={open} >
            {!loading && edits && edits.length === 0 &&
              <Typography className={classes.smaller} >
                None
              </Typography>
            }
            {!loading && edits && edits.length > 0 &&
              edits.map(edit => {
                let dateTime = new Date(edit.time);
                const offset = dateTime.getTimezoneOffset()
                dateTime = new Date(dateTime.getTime() - (offset * 60 * 1000))
                const stringTime = dateTime.toISOString().split('T')[0]
                return (
                  <div key={edit._id}>
                    <Typography className={classes.smaller} style={{ marginBottom: 0 }}>
                      {`${edit.fieldName} ➡ ${edit.value}`}
                    </Typography>
                    <Typography variant="caption" className={classes.smaller} style={{ marginLeft: '1em', fontSize: '0.7em' }}>
                      {`@${edit.userName} on ${stringTime}`}
                    </Typography>
                  </div>
                )

              })
            }
          </Collapse>

        </Grid>
      </Grid>
    </Grid>
  )
}

function ProjectTagsWithTitle({ tags }) {
  const classes = useStyles();
  const newClasses = useNewStyles();
  const [editMode, setEditMode] = React.useState(false);
  const { loading, } = useSelector(tagsEditingSelector);

  return (
    <Grid container wrap="nowrap" spacing={1}>
      <Grid item>
        <VscSymbolKeyword className={classes.iconSmall} />
      </Grid>
      <Grid item container xs>
        <Grid item>
          <Typography variant='h6' className={classes.smaller}>Additional Keywords</Typography>
        </Grid>
        {editMode &&
          <ProjectTags tags={tags} onCancel={() => setEditMode(false)} onSave={() => setEditMode(false)} />
        }

        {!editMode &&
          <Grid item container xs={12}>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton aria-label="edit" size="small" onClick={() => setEditMode(true)}>
                <MdEdit />
              </IconButton>
            </Grid>
            {loading &&
              <CircularProgress size={20} />
            }
            {!loading &&
              <ul style={{ padding: "0px", margin: "0px" }}>
                {tags.length > 0 && tags.map((tag, index) => {
                  return (
                    <li key={index} style={{ display: 'inline' }}>
                      <Chip label={tag} className={newClasses.chip} />
                    </li>
                  )
                })}
                {tags.length === 0 &&
                  <li key="none" style={{ display: 'inline' }}>
                    <Chip label={"None"} className={newClasses.chip} />
                  </li>
                }
              </ul>
            }
          </Grid>
        }
      </Grid>
    </Grid >
  )
}
