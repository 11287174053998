import { createSlice } from '@reduxjs/toolkit';
import searchApi from 'store/slices/search/search-service';
import { postMessage } from 'store/slices/messages/messages';
import { fetchChildProjects } from './childProjects';
import { fetchRelatedContracts } from './relatedContracts';
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  selectedContract: null,
}

const sliceName = 'selectedContract';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setContractFetchingStarting: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setSelectedContract: (state, { payload }) => {
      const { selectedContract } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.selectedContract = selectedContract;
    },
    setContractFetchingFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setContractFetchingStarting, setSelectedContract, setContractFetchingFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer


//this is the method that should be used if we already have the contract document, to avoid a server side trip
export const selectContract = (contract) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setContractFetchingStarting());
      dispatch(fetchRelatedContracts(contract));
      dispatch(setSelectedContract(contract));
    } catch (error) {
      dispatch(setContractFetchingFailure());
      dispatch(postMessage({ type: "warning", text: "Selecting Contract Error: " + error }));
    }
  }
}

//this is the method that should be used if we DONT already have the contract document, and just have it's id
export const fetchContract = (identifier) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setContractFetchingStarting());

      const token = await GetToken();

      const results = await searchApi.getSearch(
        identifier,
        'contracts-index',
        "",
        "",
        token.idToken,
        10,
        0,
        "",
        ""
      );

      const parsedResult = parseSearchResults(results);
      if (!parsedResult) {
        throw Error("Couldn't retrieve project with id: " + identifier);
      }
      dispatch(fetchChildProjects(parsedResult.selectedContract));
      dispatch(fetchRelatedContracts(parsedResult.selectedContract));
      dispatch(setSelectedContract(parsedResult));
    } catch (error) {
      dispatch(setContractFetchingFailure());
      dispatch(postMessage({ type: "warning", text: "Fetching Contract Error: " + error }));
    }
  }
}

export const clearSelectedContract = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setSelectedContract({ selectedContract: null }));
    } catch (error) {
      dispatch(postMessage({ type: "warning", text: "Clearing Selected Contract Error: " + error }));
    }
  }
}

//this is to parse out the somewhat ugly search results, this should probably be moved server sode
const parseSearchResults = results => {
  if (
    results.primary && results.primary.length > 0
    && results.primary[0].result
    && results.primary[0].result.value && results.primary[0].result.value.length > 0
  ) {

    const res = results.primary[0].result.value[0];
    return { selectedContract: res };
  } else {
    return null;
  }
}