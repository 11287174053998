import { Button } from "@material-ui/core";
import { Favorite } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { favouriteProject } from "store/slices/projects/favouritingProject";

export function AddToFavouritesButton({ project }) {
    const dispatch = useDispatch();
  
    return (
      <Button
        variant="text"
        size="small"
        startIcon={<Favorite />}
        style={{ width: 'fit-content', color: '#4d5156', paddingLeft: 3 }}
        color="inherit"
        onClick={() => dispatch(favouriteProject(project))}
      >
        Add to Favourites
      </Button>
    )
  }