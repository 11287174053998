import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  displayNum:70,
}

const displayNumSlice = createSlice({
  name: 'displayNum',
  initialState,
  reducers: {
    setDisplayNum: (state, {payload}) => {
        state.displayNum = payload;
    },
  },
})

export const { setDisplayNum } = displayNumSlice.actions
export const displayNumSelector = state => state.displayNum
export default displayNumSlice.reducer

export function setNum(num) {
    return async dispatch => {
      //dispatch(getPosts())
      try {
        dispatch(setDisplayNum(num))
      } catch (error) {
        console.log('error setting num')
        console.log(error)
      }
    }
  }