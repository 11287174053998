import React from 'react';
import {
  DateTimePicker,
} from '@material-ui/pickers';

//Note: This components expects a MuiPickersUtilsProvider higher up
// in the tree as per: https://material-ui-pickers.dev/getting-started/installation
const MaterialUIPickers = props => {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(new Date('2020-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <DateTimePicker
      variant="inline"
      ampm={false}
      label="DateTime picker inline"
      value={selectedDate}
      onChange={handleDateChange}
      disablePast
      format="DD/MM/YYYY  hh:mm a"
      margin="normal"
      id="datetime-picker-inline"
    />
  );
};
export default MaterialUIPickers;


