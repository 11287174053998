import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
  "currentPage": "welcome",
}

const sliceName = 'currentPage';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },

  },
})

export const { setCurrentPage, } = slice.actions
export const currentPageSelector = state => state[sliceName];
export const displayFiltersOnPageSelector = state => state[sliceName].currentPage === 'search';
export default slice.reducer
