import React from "react";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components  
import ContractCard from 'components/Contract/ContractCard';
import RelatedItemsCarousel from './RelatedItemsCarousel';  
 
const SectionCarouselResults = ({items, numberOfCards, displayIcon}) => {
  items = items || [];
  return ( 
      <>
      {Object.values(items).length > 0 &&
      <> 
        <RelatedItemsCarousel numberOfCards={numberOfCards}>
          {Object.values(items).map((searchResult, idx) => (
            <ContractCard key={searchResult.Id} contract={searchResult}  displayIcon={displayIcon}/>
          ))}
        </RelatedItemsCarousel> 
      </>
      } 
      </>
  );
}

export default SectionCarouselResults;

