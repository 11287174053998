import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  displayMessages:{},
  messages: {},
}

const sliceName = 'messages';

let currId = 1;

const types = { error: "error", warning: "warning", info: "info", success: "success" }

const getMessage = ({ type, text }) => {
  const msg = {};
  msg.id = currId;
  currId = currId + 1;

  if (!types[type]) {
    console.log('ERROR: Invalid message type: ' + type);
    type = types.info
  }
  msg.type = type;

  if (!(text + "")) {
    console.log('ERROR: Invalid message text: ' + text)
    text = ""
  }
  msg.text = text;

  return msg;
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    postMessageInternal: (state, { payload }) => {
      state.messages[payload.id] = payload;
      state.displayMessages[payload.id] = payload;
    },
    dismissMessageInternal: (state, { payload }) => {
      delete state.displayMessages[payload.id]
    },
  },
})
//the below actions deconstruction names need to sync up with the above reducers
export const { postMessageInternal, dismissMessageInternal } = slice.actions


export const messagesSelector = state => state[sliceName].messages;
export const displayMessagesSelector = state => state[sliceName].displayMessages;

export default slice.reducer

/**
 * provide @type, @text, and @timeout to post a message to the notifications / snackbar.
 * must enter -1 explicitly for no timeout, otherwise it will default to 5000
 * @param {*} param0 
 * @returns 
 */
export const postMessage = ({ type, text, timeout }) => {
  return async dispatch => {
    try {// just an example, probs unnesecary for something local.
      const msg = getMessage({ type, text });
      dispatch(postMessageInternal(msg));
      if (timeout !== -1) {
        setTimeout(() => dispatch(dismissMessage({id:msg.id})), timeout || 5000);
      }

    } catch (error) {
      console.log('Error with message post: ' + error);
    }
  }
}

export const dismissMessage = ({ id }) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      const state = getState();
      if (id in state.messages.messages) {
        dispatch(dismissMessageInternal({ id }))
      } else {
        console.log('msg already dismissed: ' + id);
      }

    } catch (error) {
      console.log('Error with dismissing message: ' + error);
    }
  }
}