import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { selector as searchMoreLikeThisSelector } from 'store/slices/search/searchMoreLikeThis';
import { setSearchText } from 'store/slices/search/searchText';
import { setSelected } from 'store/slices/search/searchSelected';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "@material-ui/core";
// react components for routing our app without refresh
import { useHistory } from 'react-router-dom';
import { pushPage } from 'pages/_utils';
import Typography from '@material-ui/core/Typography';

const SectionMoreLikeThisResults = props => {
  const style = {
    title: {
      marginBottom: "1rem",
      marginTop: "30px",
      minHeight: "32px",
      textDecoration: "none"
    },
    description: {
      color: "#70757a",
      fontSize: "14px"
    },
    link: {
      fontSize: "14px", 
      fontWeight: 400,
      cursor: 'pointer'
    },
    icon: {
      fontSize: '1.3em',
      marginBottom: '-5px',
      marginRight: '5px',
      color: "#70757a"
    },
    section: {
      marginBottom: 50 
    },
    headerStyle: {
      marginTop: 35,
      color: "#70757a",
      fontWeight: 400
    },
    gridWrapper: {
      width: 'auto;',
      display: 'grid',
      gridTemplateColumns: 'repeat(1, 90%)'
    },
    gridItem: {
      paddingLeft: 0,
      paddingRight: 25,
      marginBottom: 10,
    },
    paper: {
      padding: 15,
      borderRadius: 8,
      backgroundColor: "transparent",
    },
    selectedTitle: {
      margin: "1.0rem 0 0.425rem !important",
      minHeight: "unset",
      "& a": {
        textDecoration: 'none'
      },
      fontWeight: 400,
      color: '#4d5156'
    },
  };

  const useStyles = makeStyles(style);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { moreLikeThis } = useSelector(searchMoreLikeThisSelector);

  const executeSearch = (search, id) => {
    dispatch(setSelected(id))
    dispatch(setSearchText(search)) 

    pushPage(history, '/search', { searchQuery: search, page: 1 });
  } 
 
  return (
    <div className={classes.section} ref={props.scrollRef}>
      {Object.values(moreLikeThis).length > 0 && 
        <Typography variant='h4' className={classes.selectedTitle}>
          Related Searches
        </Typography>
      }
      <div className={classes.gridWrapper}>
        {Object.values(moreLikeThis)
          .map((result, index) => { 
            return (
            <Link color="primary" key={index} className={classes.link} onClick={(e) => {
              e.preventDefault()
              executeSearch(result.ProjectName, result.ProjectId)
            }}
            >
              {result.ProjectName} 
            </Link>
          )})}
      </div>
    </div>
  );
}

export default SectionMoreLikeThisResults;