import React, { useState } from "react";
// react components for routing our app without refresh
import Link from "@material-ui/core/Link";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Event from "@material-ui/icons/EventOutlined";
import RouterLink from 'components/routing/Link';
import AccountBox from "@material-ui/icons/AccountBoxOutlined";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import Grid from '@material-ui/core/Grid';
import DirectoryDisplay from 'components/DirectoryDisplay/DirectoryDisplay';
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import Collapse from "@material-ui/core/Collapse";
import clsx from 'clsx';
import FolderIcon from "@material-ui/icons/FolderOpen";
import { MarketSegmentIcon } from "components/Icons/MarketSegmentIcons";
import { Avatar,  } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import MuiBadge from "components/Badge/MuiBadge";
import { ClientIcon } from "components/Icons/ClientIcon";
import { ServiceLineIcon } from "components/Icons/ServiceLineIcon";
import ContractIconOutlined from "components/Icons/ContractIconOutlined";
import useIsActiveProject from "hooks/useIsActiveProject";
import { AddToFavouritesButton } from "components/CustomButtons/AddToFavourites";

const transitionTime = 300;

const useStyles = makeStyles(theme => ({

  infoArea: {
    margin: "0 auto",
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  descriptionWrapper: {
    color: theme.palette.grey[400],
    overflow: "hidden"
  },
  selectedTitle: {
    margin: "0rem 0 0.275rem !important",
    minHeight: "unset",
    "& a": {
      textDecoration: 'none'
    },
    fontWeight: 400,
    color: '#4d5156'
  },
  title: {
    margin: "2rem 0 0.875rem !important",
    minHeight: "unset",
    "& a": {
      textDecoration: 'none'
    },
    fontWeight: 400,
    color: '#4d5156'
  },
  description: {
    overflow: "hidden",
    marginTop: "0px",
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 400
  },
  descriptionStrong: {
    overflow: "hidden",
    marginTop: 15,
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    marginBottom: 5,
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 600
  },
  gridDescription: {
    overflow: "hidden",
    marginTop: 0,
    marginBottom: 5,
    "& p": {
      color: '#4d5156',
      fontSize: "14px"
    },
    color: "#4d5156",
    fontSize: "14px",
    fontWeight: 400
  },
  smaller: {
    overflow: "hidden",
    marginTop: "5px",
    "& p": {
      color: '#4d5156',
      fontSize: "12px"
    },
    color: "#4d5156",
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: 0,
    letterSpacing: 'normal',
    textTransform: 'none'
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  },
  icon: {
    fontSize: '1.3em',
    marginBottom: '-3px',
    marginRight: '5px'
  },
  iconSmall: {
    fontSize: '1.3em',
    marginBottom: '-2.5px',
    marginRight: '5px',
    color: "#4d5156",
  },
  iconSmallExanded: {
    fontSize: '1.3em',
    marginBottom: '-3.75px',
    marginRight: '5px',
    color: "#4d5156",
  },
  section: {
    paddingTop: 0
  },
  link: {
    fontSize: "14px",
    textDecoration: "none",
    fontWeight: 400,
    marginRight: 12.5
  },
  main: {
    marginTop: 150
  },
  paper: {
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    backgroundColor: 'transparent',
    borderRadius: 8
  },
  paperResult: {
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },
  resultItem: {
    padding: 25
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    marginRight: 'auto',
    transition: theme.transitions.create('transform', {
      duration: transitionTime,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandContainer: {//this shouldn't be necessary but there's a weird margin issue at the top of this component
    marginTop: theme.spacing(0.5) * -1,//this hack pushes it up a little to compensate.
    marginBottom: theme.spacing(2),
  },
  titleDescription: {
    margin: "0.275rem 0 0rem !important",
    color: "#4d5156"
  },
  avatar: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  projectIcon: {
    fontSize: '24px',
  },
}));




export default function ProjectSearchResult({ item, initiallyExpanded }) {
  //props & styles
  const classes = useStyles();

  //state
  const [expanded, setExpanded] = useState(initiallyExpanded ? true : false);

  //event listeners
  const handleClick = event => {
    setExpanded(!expanded);
  }

  const activeProject = useIsActiveProject(item.ProjectStartDate, item.ProjectFinishDate);

  const projectLink = `/project/${item.ProjectId}`;
  return (
    <Grid container direction="column">
      <Grid container item direction="row" spacing={1}>
        <Grid item container justifyContent="center" alignItems="center" style={{ width: 'auto' }}>
          <Grid item>
            <MuiBadge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent=" "
              variant='dot'
              color={activeProject ? "active" : "inactive"}
              size={10}
            >
              <Avatar className={classes.avatar}>
                <MarketSegmentIcon className={classes.projectIcon} marketSegment={item.ProjectMarketSegmentName} />
              </Avatar>
            </MuiBadge>
          </Grid>
        </Grid>
        <Grid container item direction="column" style={{ width: 'auto' }} xs={10}>
          <Typography variant="caption" display="block" className={classes.titleDescription}>
            {item.ProjectCode}
          </Typography>

          <Typography variant='h4' className={classes.selectedTitle}>
            <Link component={RouterLink} to={projectLink}>
              {item.ProjectName}
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <p className={classes.description} style={{ marginTop: '0px', marginBottom: '0px' }} >
        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].ProjectManagerName ? item['@search.highlights'].ProjectManagerName : item.ProjectManagerName
        }}>
        </span>{", "}
        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].ProjectManagerHomeOrgName ? item['@search.highlights'].ProjectManagerHomeOrgName : item.ProjectManagerHomeOrgName
        }}>
        </span>{", "}
        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].ProjectDirectorName ? item['@search.highlights'].ProjectDirectorName : item.ProjectDirectorName
        }}>
        </span>{", "}
        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].ProjectDirectorHomeOrgName ? item['@search.highlights'].ProjectDirectorHomeOrgName : item.ProjectDirectorHomeOrgName
        }}>
        </span>{", "}
        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].ProjectOrgName ? item['@search.highlights'].ProjectOrgName : item.ProjectOrgName
        }}>
        </span>{", "}

        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].Tasks ? item['@search.highlights'].Tasks[0].slice(0, 100) : (item.Tasks) ? item.Tasks.slice(0, 100) : " "
        }}>
        </span>{" ... "}

        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].TaskManagers ? item['@search.highlights'].TaskManagers[0].slice(0, 100) : (item.TaskManagers) ? item.TaskManagers.slice(0, 100) : " "
        }}>
        </span>{" ... "}

        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].TaskOrgs ? item['@search.highlights'].TaskOrgs[0].slice(0, 100) : (item.TaskOrgs) ? item.TaskOrgs.slice(0, 100) : " "
        }}>
        </span>{" ... "}

        <span dangerouslySetInnerHTML={{
          __html:
            item['@search.highlights'] && item['@search.highlights'].Resources ? item['@search.highlights'].Resources[0].slice(0, 100) : (item.Resources) ? item.Resources.slice(0, 100) : " "
        }}>
        </span>
      </p>
      <Collapse in={expanded}>
        <AddToFavouritesButton project={{ ProjectId: item.ProjectId, ProjectName: item.ProjectName }} />
      </Collapse>
      <div style={{ height: '14px', width: '100%' }} />

      <div className={classes.cardDescriptionWhite}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ClientIcon className={expanded ? classes.iconSmallExanded : classes.iconSmall} onClick={() => setExpanded(!expanded)} />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Client</Typography>
                </Collapse>
                <Typography className={classes.description}>
                  {item.ProjectClientName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ContractIconOutlined className={expanded ? classes.iconSmallExanded : classes.iconSmall} onClick={() => setExpanded(!expanded)} />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Contract</Typography>
                </Collapse>
                <Typography className={classes.description}>
                  {(item.ContractName && item.Contract) &&
                    <Link component={RouterLink} to={"/contract/" + item.Contract}>
                      {item.ContractName + (item.ContractCode ? " | " + item.ContractCode : "")}
                    </Link>
                  }
                  {!(item.ContractName && item.Contract) &&
                    "Not found"
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <MarketSegmentIcon
                  className={expanded ? classes.iconSmallExanded : classes.iconSmall}
                  marketSegment={item.ProjectMarketSegmentName}
                  onClick={() => setExpanded(!expanded)}
                />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Market Segment</Typography>
                </Collapse>
                <Typography className={classes.description}>
                  {item.ProjectMarketSegmentName || "Not found"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ColorLensOutlinedIcon
                  className={expanded ? classes.iconSmallExanded : classes.iconSmall}
                  onClick={() => setExpanded(!expanded)}
                />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Studio</Typography>
                </Collapse>
                <Typography className={classes.description}>
                  {item.ProjectBusinessUnitName || "Not found"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>

          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ServiceLineIcon
                  className={expanded ? classes.iconSmallExanded : classes.iconSmall}
                  onClick={() => setExpanded(!expanded)}
                />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Service Line</Typography>
                </Collapse>
                <Typography className={classes.description}>
                  {item.ServiceLineName || "Not found"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <Event
                  className={expanded ? classes.iconSmallExanded : classes.iconSmall}
                  onClick={() => setExpanded(!expanded)}
                />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Project Dates</Typography>
                </Collapse>
                <Typography className={classes.description}>
                  {new Date(item.ProjectStartDate).toISOString().split('T')[0]} to {new Date(item.ProjectFinishDate).toISOString().split('T')[0]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>





        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <AccountBox
                  className={expanded ? classes.iconSmallExanded : classes.iconSmall}
                  onClick={() => setExpanded(!expanded)}
                />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Project Manager</Typography>
                </Collapse>
                <Typography className={classes.description}>
                  <span dangerouslySetInnerHTML={{
                    __html:
                      item['@search.highlights'] && item['@search.highlights'].ProjectManagerName ? item['@search.highlights'].ProjectManagerName : item.ProjectManagerName
                  }}>
                  </span>{", "}
                  <span dangerouslySetInnerHTML={{
                    __html:
                      item['@search.highlights'] && item['@search.highlights'].ProjectManagerHomeOrgName ? item['@search.highlights'].ProjectManagerHomeOrgName : item.ProjectManagerHomeOrgName
                  }}>
                  </span>
                </Typography>

                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <>
                    <Typography variant='h6' className={classes.smaller}>Principal In Charge</Typography>
                    <Typography className={classes.description}>
                      <span dangerouslySetInnerHTML={{
                        __html:
                          item['@search.highlights'] && item['@search.highlights'].ProjectDirectorName ? item['@search.highlights'].ProjectDirectorName : item.ProjectDirectorName
                      }}>
                      </span>{", "}
                      <span dangerouslySetInnerHTML={{
                        __html:
                          item['@search.highlights'] && item['@search.highlights'].ProjectDirectorHomeOrgName ? item['@search.highlights'].ProjectDirectorHomeOrgName : item.ProjectDirectorHomeOrgName
                      }}>
                      </span>
                    </Typography>

                  </>
                </Collapse>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <>
                    <Typography variant='h6' className={classes.smaller}>Project Organization</Typography>
                    <Typography className={classes.description}>
                      <span dangerouslySetInnerHTML={{
                        __html:
                          item['@search.highlights'] && item['@search.highlights'].ProjectOrgName ? item['@search.highlights'].ProjectOrgName : item.ProjectOrgName
                      }}>
                      </span>
                    </Typography>
                  </>
                </Collapse>
                {/* <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}> //removed because this didn't seem populated and it messed up the layout with task mans/orgs/res's
                  <>
                    <Typography variant='h6' className={classes.smaller}>Related Projects</Typography>
                    <Typography className={classes.description}>
                      {item.RelatedProjects.length === 0 &&
                        "No related projects found"
                      }
                      {item.RelatedProjects.map((related, index, array) => {
                        return (
                          <Link key={index} component={RouterLink} to={"/Project/" + related.Key} className={classes.link}>
                            {related.Value}<br></br>
                          </Link>
                        )
                      })
                      }
                    </Typography>
                  </>
                </Collapse> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <AssignmentTurnedInOutlined
                  className={expanded ? classes.iconSmallExanded : classes.iconSmall}
                  onClick={() => setExpanded(!expanded)}
                />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Task Managers</Typography>
                </Collapse>
                <Typography className={classes.description}>
                  <span dangerouslySetInnerHTML={{
                    __html:
                      item['@search.highlights'] && item['@search.highlights'].TaskManagers ? item['@search.highlights'].TaskManagers[0] : (item.TaskManagers) ? item.TaskManagers : "No task managers found"
                  }}>
                  </span>
                </Typography>

                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <>
                    <Typography variant='h6' className={classes.smaller}>Task Organizations</Typography>
                    <Typography className={classes.description}>
                      <span dangerouslySetInnerHTML={{
                        __html:
                          item['@search.highlights'] && item['@search.highlights'].TaskOrgs ? item['@search.highlights'].TaskOrgs[0] : (item.TaskOrgs) ? item.TaskOrgs : "No task organizations found "
                      }}>
                      </span>v
                    </Typography>

                    <Typography variant='h6' className={classes.smaller}>Task Resources</Typography>
                    <Typography className={classes.description}>
                      <span dangerouslySetInnerHTML={{
                        __html:
                          item['@search.highlights'] && item['@search.highlights'].Resources ? item['@search.highlights'].Resources[0] : (item.Resources) ? item.Resources : "No resources found "
                      }}>
                      </span>
                    </Typography>
                  </>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <FolderIcon
                  className={expanded ? classes.iconSmallExanded : classes.iconSmall}
                  onClick={() => setExpanded(!expanded)}
                />
              </Grid>
              <Grid item xs>
                <Collapse in={expanded} timeout={transitionTime} unmountOnExit={false}>
                  <Typography variant='h6' className={classes.smaller}>Network Folder</Typography>
                </Collapse>
                <DirectoryDisplay directory={item.NetworkDirectory_current} hideCopyButton={true} editLink={projectLink} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container className={classes.expandContainer} >
        <IconButton
          className={
            clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
          size='small'
          onClick={handleClick}>
          <ExpandMore />
        </IconButton>
      </Grid>
    </Grid >//root level
  );
}
