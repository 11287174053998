import React from 'react';
import Button from './Button';
import { makeStyles } from "@material-ui/core/styles";
import { authSelector, loginButton, logoutButton } from 'store/slices/auth/auth';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch, useSelector } from 'react-redux';
import ListItem from "@material-ui/core/ListItem";
import { Collapse, List, } from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FacetsVerticalList } from 'components/Header/FacetsSection/FacetsSection';
import { displayFiltersOnPageSelector } from 'store/slices/routing/currentPage';
import { ActiveOnlyToggle } from 'components/Header/FacetsSection/ActiveOnlyToggle';
import { MobileNotificationList } from 'components/Notifications/NotificationsList';
import { MobileFavouritesList } from 'components/Favourites/FavouritesList';
import { MobileLogo } from 'components/Header/Logo';

const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        cursor: 'pointer',
        fontSize: '100%'
    },
    icons: {
        width: "20px",
        height: "20px",
        marginRight: "14px"
    },
    dropdownIcons: {
        width: "24px",
        height: "24px",
        marginRight: "14px",
        opacity: "0.5",
        marginTop: "-4px",
        top: "1px",
        verticalAlign: "middle",
        fontSize: "24px",
        position: "relative"
    },
    listItem: {
        color: "inherit"
    },
    listLink: {
        "&,&:hover,&:focus": {
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            padding: "0.15rem 0.25rem 0.15rem 0.0rem"
        }
    },
    dropdownLink: {
        "&,&:hover,&:focus": {
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            padding: "0.75rem 1.25rem 0.75rem 0.75rem"
        }
    },
    navButton: {
        position: "relative",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        transition: 'transform .3s ease-out',
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 30px)",
            marginLeft: "15px",
            marginBottom: "5px",
            marginTop: "5px",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start"
            }
        },
        "& $icons": {
            marginRight: "3px"
        },
        '&:hover': {
            transform: 'translate(0,-3px)'
        }
    },
}));




const LoginList = props => {
    const classes = useStyles();
    const { authenticated, loggingInToAuth, loggingInToApp, user } = useSelector(authSelector);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logoutButton())
    }
    const login = () => {
        dispatch(loginButton())
    }
    const loading = loggingInToApp || loggingInToAuth;

    const [accountOpen, setAccountOpen] = React.useState(false);
    const handleAccountOpen = () => {
        setAccountOpen(!accountOpen);
    };

    const [filtersOpen, setFiltersOpen] = React.useState(true);
    const handleFiltersOpen = () => {
        setFiltersOpen(!filtersOpen);
    };

    const displayFiltersOnPage = useSelector(displayFiltersOnPageSelector);

    return (
        <>
            {!authenticated &&
                <Button
                    color={window.innerWidth < 960 ? "info" : "white"}
                    target="_blank"
                    className={classes.navButton}
                    onClick={loading ? null : login}
                    round
                >
                    <AccountCircleIcon className={classes.icons} />
                    {(!loggingInToAuth && !loggingInToApp) &&
                        "Login"
                    }
                    {(loggingInToAuth && !loggingInToApp) &&
                        "..."
                    }
                    {(!loggingInToAuth && loggingInToApp) &&
                        "... ..."
                    }
                </Button>
            }
            {(authenticated && !loading) &&
                <>
                    <MobileLogo />
                    <MobileNotificationList />
                    <MobileFavouritesList />
                    <ListItem button className={classes.listItem} onClick={handleAccountOpen}>
                        {user.name}
                        {accountOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={accountOpen} timeout="auto" >
                        <List component="div" disablePadding>
                            <ListItem className={classes.listItem}>
                                <Link
                                    to="/profile-page"
                                    component={RouterLink}
                                    className={classes.listLink}
                                >
                                    <AccountCircleIcon className={classes.dropdownIcons} />
                                    Account
                                </Link>
                            </ListItem>

                            <ListItem className={classes.listItem}>
                                <Link
                                    to="/users-page"
                                    component={RouterLink}
                                    className={classes.listLink}>
                                    <PeopleIcon className={classes.dropdownIcons} />
                                    Manage Users
                                </Link>
                            </ListItem>

                            <ListItem className={classes.listItem}>
                                <Link
                                    className={classes.listLink}
                                    onClick={logout}
                                >
                                    <ExitToAppIcon className={classes.dropdownIcons} />
                                    Signout
                                </Link>
                            </ListItem>
                        </List>
                    </Collapse>
                    {displayFiltersOnPage &&
                        <ListItem button className={classes.listItem} onClick={handleFiltersOpen}>
                            Filters
                            {filtersOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    }
                    {displayFiltersOnPage &&
                        <Collapse in={filtersOpen} timeout="auto" >
                            <List component="div" disablePadding>
                                <ListItem>
                                    <ActiveOnlyToggle labelPlacement="end" />
                                </ListItem>
                                <FacetsVerticalList />
                            </List>
                        </Collapse>
                    }
                </>
            }
        </>

    )
};



export default LoginList;