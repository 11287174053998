import React from "react";
// react component for creating beautiful carousel
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';

import Carousel from 'components/Carousel/Carousel';


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import carouselStyle from "./sectionCarouselStyle.js";

import image1 from "img/bg.jpg";
import image2 from "img/bg2.jpg";
import image3 from "img/bg3.jpg";

const useStyles = makeStyles(carouselStyle);

export default function SectionCarousel() {
  const classes = useStyles();
  const legendStyle = {
    position: 'absolute',
    bottom: '50px',
    color: '#ffffff',
    left: '50%',
    WebkitTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)'
  }
  const imgs = [
    {
      src: image1,
      alt: 'First Slide',
      title: 'Yellowstone National Park, United States'
    },
    {
      src: image2,
      alt: 'Second Slide',
      title: 'Somewhere Beyond, United States'
    },
    {
      src: image3,
      alt: 'Third Slide',
      title: 'Purplestone National Ark, United States'
    },
  ]
  return (
    <div className={classes.container} >
      <div className={classes.section} >
        <GridContainer>
          <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
            <Card>
              <Carousel              >
                {imgs.map((img, index) => (
                  <div key={index+'imagecarousel'}>
                    <img
                      src={img.src}
                      alt={img.alt}
                    />
                    <div
                      style={legendStyle}
                    >
                      <Typography variant='h4'>
                        <LocationOn />
                        {img.title}
                      </Typography>
                    </div>
                  </div>
                ))}
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
    </div>
    </div >
  );
}
