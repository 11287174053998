//imports
import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from 'components/Table/Table';
import Link from "@material-ui/core/Link";

import contactStyle from "./sectionUsersStyle.js";

import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers, usersSelector } from '../../../../store/slices/auth/users';
import { authSelector } from '../../../../store/slices/auth/auth';

import RoleSelector from './RoleSelector';
import { roles } from '../../../Auth/rbac-rules';

//singletons
const useStyles = makeStyles(contactStyle);
const roleArray = [];
Object.keys(roles).forEach(role => roleArray.push({item:role, display:role, key:role}));

//render function
const SectionUsers = props => {
  //state
  const { authenticated, idToken } = useSelector(authSelector);
  const { users } = useSelector(usersSelector);

  //other hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  //effects
  useEffect(() => {
    if (authenticated && idToken) {
      dispatch(fetchUsers(idToken.idToken));
    }
  }, [dispatch, authenticated, idToken]);

  //custom code & props handling
  const tableData = Object.values(users).map(usr => [
    usr.name,
    usr.email,
    (<RoleSelector
      header={"Roles"}
      items={roleArray}
      selectedItem={usr.role}
      //onItemSelected={}
    />)
  ]);


  //final jsx object
  return (
    <div className={classes.aboutUs}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <Typography variant='h2' className={classNames(classes.title, classes.textCenter)}>
            {"Application Level Roles"}
          </Typography>
          <Typography variant='h4' className={classNames(classes.description, classes.textCenter)}>
            This is the area for managing <i>application</i> level roles and permissions. To manage
            project specific security, head <Link>here</Link> to find your project.
          </Typography>
          <GridContainer>
            <GridItem
              md={12}
              sm={12}
              className={classNames(
                classes.mrAuto,
                classes.mlAuto,
                classes.textCenter
              )}
            >
              <Table
                tableHead={[
                  "Name",
                  "Email",
                  "Role",
                ]}
                tableData={tableData}
                customCellClasses={[
                  classes.textCenter,
                  classes.textRight,
                  classes.textRight
                ]}
                customClassesForCells={[2, 2]}
                customHeadCellClasses={[
                  classes.textCenter,
                  classes.textRight,
                  classes.textRight
                ]}
                customHeadClassesForCells={[2, 2]}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

//exports
export default SectionUsers;